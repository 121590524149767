import DirectoryImageInitials from "../atom/directoryImageInitials";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../context/SearchContext";
import { IoSearchOutline } from "react-icons/io5";
import { DEFAULT_LOCATION_DISTANCE } from "../../utils/constants";
import { sendMixpanelEvent } from "../../services/mixpanelService";

export default function DropdownCard(props) {
  const { inputValueRef } = useSearch();
  const {
    resetSearch,
    historySearchApi,
    topNavDirectorySearchApi,
    topNavDirectorySuggestionApi,
    suggestionSearchDetailsData,
    getSearchFilterCategoryApi,
    getLocationDetails,
  } = TopNavSearchService((state) => state);

  let {
    suggestionsData,
    setCurrentPage,
    currentPage,
    dropdownRef,
    searchInDashboard,
    setIsDropdownVisible,
    formattedAddress,
    searchHistory,
    searchData,
    setSearch,
  } = props || {};
  const {
    setSearchViewToggle,
    // setIsDropdownVisible,
    searchViewToggle,
    setSearchTerm,
    hideDropdown,
    setSelectedLocation,
  } = useSearch();

  let navigate = useNavigate();

  const handleBusinessClick = (business) => {
    setSearchTerm(searchInDashboard);
    setSelectedLocation(formattedAddress);
    let businessid = business?.id || "";
    let { google_place_id } = business?.google_place_id || "";
    setSearchTerm(business?.name);
    setSearchViewToggle(false);
    sendMixpanelEvent("Business Searched");

    hideDropdown();
    setIsDropdownVisible(false);
    navigate(`/business/${google_place_id ? google_place_id : businessid}`, {
      state: {
        googleBusinessId: google_place_id,
        businessid: businessid,
        tab: "searchTab",
      },
    });
  };
  const historySearch = (items) => {
    setIsDropdownVisible(false);
    setSearchViewToggle(true);
    if (setSearch) {
      setSearch(items);
    }
    if (setSearchTerm) {
      setSearchTerm(items);
    }
    inputValueRef.current = items;
    topNavDirectorySearchApi({
      LocationDistance: DEFAULT_LOCATION_DISTANCE,
      keyword: items,
      locationLat: searchData?.locationLat,
      locationLong: searchData?.locationLong,
    });
  };

  return (
    <div
      ref={dropdownRef}
      className="flex max-h-[300px]  flex-col w-1/2 lg:w-11/12 my-2 rounded-lg  divide-y-[1px] dark:divide-darkModeBorder"
    >
      {Array?.isArray(searchHistory) &&
        searchHistory?.length !== 0 &&
        searchHistory?.map((items, index) => (
          <div
            key={index}
            className="w-[460px] h-[65px]  flex items-center py-2 hover:bg-lightBg hover:cursor-pointer dark:hover:bg-darkModeBG"
            onClick={() => {
              historySearch(items);
            }}
          >
            <div className=" flex items-center  ">
              <div className=" w-[34px] h-[34px] rounded-md border-[1px] border-gray-100 ml-4 bg-white flex justify-center items-center p-[6.8px]">
                <IoSearchOutline className="text-black  w-[20.4px] h-[20.4px] " />
              </div>
              <p className="px-[10px]"> {items}</p>
            </div>
          </div>
        ))}
      {suggestionsData?.length !== 0 &&
        suggestionsData?.map((business, index) => (
          <div
            key={index}
            onClick={() => {
              historySearchApi({
                title: searchInDashboard,
                search_type: "local",
              });
              handleBusinessClick(business);
            }}
            className="w-[380px] h-[65px] flex items-center py-2 hover:bg-lightBg hover:cursor-pointer dark:hover:bg-darkModeBG"
          >
            <DirectoryImageInitials
              imageUrl={business?.upload?.file_path}
              alt={business?.name}
              name={business?.name}
              boxColor={business?.business_color?.toLowerCase()}
              className="!w-[55px] !h-[55px] ml-4"
            />

            <div className="flex flex-col px-2">
              <p className="font-semibold text-[13px] truncate max-w-[200px] overflow-hidden line-clamp-1">
                {business.name}
              </p>
              <div className="flex items-center gap-2">
                {business?.business_locations?.[0]?.distance && (
                  <p className="text-[12px] line-clamp-1">
                    {`${business?.business_locations?.[0]?.distance.toFixed(
                      1
                    )}mi -`}
                  </p>
                )}
                {business?.business_locations?.[0]?.address && (
                  <p className="text-[12px] truncate line-clamp-1 max-w-[200px] max-[1350px]:max-w-[50px]">
                    {business?.business_locations?.[0]?.address}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
