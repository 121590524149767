import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import prop-types for prop validation
import { IoCloseOutline, IoOptionsOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { RxPlus } from "react-icons/rx";
import AddVaultItem from "./AddVaultItem";
import { VaultsService } from "../../../services/vaults/VaultsService";
import { getAccountType, getCompanyId } from "../../../routes/ProtectedRoutes";
import { PRO_USER } from "../../../utils/constants";
import VaultItemCustomComponent from "./VaultItemsCustomComponent";

export default function IdentitiesView({ businessDetails }) {
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [viewType, setViewType] = useState("");
  const [vaultData, setVaultData] = useState([]);
  const [vaultItemsData, setVaultItemsData] = useState([]);
  const [filteredVaultItemsData, setFilteredVaultItemsData] = useState([]);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dropdownIdentify, setDropdownIdentify] = useState("");
  const [editItemView, setEditItemView] = useState(false);


  const { listAllVaultsApi } = VaultsService();
  const accountType = getAccountType();
  const companyId = accountType === PRO_USER ? getCompanyId() : null;

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordionId(openAccordionId === accordionId ? null : accordionId);
  };

  // Search functionality
  const handleSearch = () => {
    setIsInputVisible(true);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value === "") {
      // Reset vault items when input is cleared
      setFilteredVaultItemsData(vaultItemsData);
    } else {
      // Filter vault items based on the 'name' field (or another if needed)
      const filteredVaults = vaultItemsData.filter(
        (vaultItem) =>
          vaultItem?.item_name?.toLowerCase().includes(value.toLowerCase()), // Adjust 'name' if needed
      );
      setFilteredVaultItemsData(filteredVaults);
    }
  };

  // Fetch vault details
  const fetchVaultData = async () => {
    try {
      const queryParams = {
        ...(accountType === PRO_USER && { companyId: companyId }),
        businessId: businessDetails?.id,
      };
      const response = await listAllVaultsApi(queryParams);
      setVaultData(response);
      if (response.length !== 0 && response[0]?.vault_items.length !== 0) {
        setVaultItemsData(response[0]?.vault_items);
        setFilteredVaultItemsData(response[0]?.vault_items); // Set filtered data initially
      } else {
        setVaultItemsData([]);
        setFilteredVaultItemsData([]);
      }
    } catch (error) {
      console.error("Error fetching vault data:", error);
    }
  };

  useEffect(() => {
    fetchVaultData();
  }, []);

  return (
    <div className="w-full text-sm min-h-screen border-[1px] border-gray-100 dark:border-darkModeBorder bg-white rounded-lg dark:bg-darkModeMain flex flex-col">
      <div className="flex justify-between items-center w-full h-[60px] min-h-[60px] border-b border-gray-100 dark:border-darkModeBorder">
        <div className="flex items-center gap-4 p-6 text-sm">
          <p className="font-semibold uppercase">Vault</p>
          {/* <button className="flex items-center gap-1 ml-4 text-ipurple hover:text-ipurple">
            <p>All</p>
            <p>0</p>
          </button>
          <button className="items-center hidden gap-1 hover:text-ipurple">
            <p>Shared</p>
            <p>0</p>
          </button>
          <button className="flex items-center gap-1 hover:text-ipurple">
            <p>Archived</p>
            <p>0</p>
          </button> */}
        </div>
        <div className="flex items-center gap-4 p-3">
          {/* <IoOptionsOutline size={20} /> */}
          {isInputVisible ? (
            <div className="relative flex items-center pl-2">
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className="border rounded p-1  dark:bg-darkModeMain dark:border-darkModeBorder"
                placeholder="Search vaults..."
              />
              <button
                onClick={() => setIsInputVisible(false)}
                className="absolute right-2"
                type="button"
              >
                <IoCloseOutline size={25} />
              </button>
            </div>
          ) : (
            <IoIosSearch size={20} onClick={handleSearch} />
          )}
          <button>
            <RxPlus
              size={20}
              onClick={() => {
                setViewType("Add Vault Item");
              }}
            />
          </button>
        </div>
      </div>

      {/** Render existing VaultItems component */}
      {filteredVaultItemsData.length !== 0 && viewType !== "Add Vault Item" && (
        <VaultItemCustomComponent
          vaultItemsData={filteredVaultItemsData}
          vaultId={vaultData[0].id}
          onRemove={fetchVaultData}
          onUpdate={fetchVaultData}
          dropdownIdentify={dropdownIdentify}
          setDropdownIdentify={setDropdownIdentify}
          editItemView={editItemView}
          setEditItemView={setEditItemView}

          
        />
      )}

      {/** Render Create VaultItem component */}
      {viewType === "Add Vault Item" ? (
        <AddVaultItem
          onSave={() => {
            fetchVaultData();
            setViewType("");
          }}
          businessDetails={businessDetails}
          vault={vaultData.length > 0 && { ...vaultData[0] }}
        />
      ) : null}
    </div>
  );
}

// Add prop-types validation
IdentitiesView.propTypes = {
  businessDetails: PropTypes.shape({
    id: PropTypes.string.isRequired, // Adjust type if necessary (e.g., number, etc.)
  }).isRequired,
};
