import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
export const getTimeDiffDetails = (review_date) => {
  const diff = dayjs().diff(dayjs(review_date));
  const daysDiff = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hoursDiff = Math.floor(
    (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutesDiff = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  if (daysDiff > 0) {
    if (daysDiff >= 7) {
      const weeksDiff = Math.floor(daysDiff / 7);
      return `${weeksDiff}w ago`;
    } else {
      return `${daysDiff}d ago`;
    }
  } else if (hoursDiff > 0) {
    return `${hoursDiff}h ${minutesDiff}m ago`;
  } else {
    return `${minutesDiff}m ago`;
  }
};

const getScheduledTimeDisplay = (scheduledTime) => {
  const now = dayjs();

  const scheduled = dayjs(scheduledTime).local();
  if (scheduled.isSame(now, "day")) {
    return `Today ${scheduled.format("hh:mm a")}`;
  } else if (scheduled.isSame(now.clone().add(1, "day"), "day")) {
    return `Tomorrow ${scheduled.format("hh:mm a")}`;
  } else {
    return `${scheduled.format("MMMM D, YYYY hh:mm a")}`;
  }
};

export const getTranscriptTimeDisplay = (scheduledTime) => {
  const now = dayjs();
  const scheduled = dayjs.utc(scheduledTime);

  if (scheduled.isSame(now, "day")) {
    return `Today ${scheduled.format("hh:mm a")}`;
  } else if (scheduled.isSame(now.clone().add(1, "day"), "day")) {
    return `Tomorrow ${scheduled.format("hh:mm a")}`;
  } else {
    return `${scheduled.format("MMMM D, YYYY hh:mm a")}`;
  }
};

export default getScheduledTimeDisplay;

export const getScheduledTimeDisplayNow = (scheduledTime) => {
  const now = dayjs();
  const scheduled = dayjs(scheduledTime);
  if (scheduled.isSame(now, "day")) {
    return `Today - ${scheduled.format("hh:mm A")}`;
  } else if (scheduled.isSame(now.clone().add(1, "day"), "day")) {
    return `Tomorrow - ${scheduled.format("hh:mm A")}`;
  } else {
    return `${scheduled.format("MMMM D, YYYY hh:mm A")}`;
  }
};
export const getScheduledCurrentTimeDisplay = (scheduledTime) => {
  const now = dayjs();
  // const scheduled = dayjs.utc(scheduledTime);
  const scheduled = dayjs(scheduledTime);
  if (scheduled.isSame(now, "day")) {
    return `${scheduled.format("hh:mm A")}`;
  } else if (scheduled.isSame(now.clone().add(1, "day"), "day")) {
    return `${scheduled.format("hh:mm A")}`;
  } else {
    return `${scheduled.format("MMMM D, YYYY hh:mm a")}`;
  }
};

export const getDayTimeDiffDetails = (review_date) => {
  const daysDiff = dayjs().diff(dayjs(review_date), "day");

  if (daysDiff < 1) {
    const hoursDiff = dayjs().diff(dayjs(review_date), "hour");
    const minutesDiff = dayjs().diff(dayjs(review_date), "minute");
    if (hoursDiff < 1) {
      return `${minutesDiff} m ago`;
    } else {
      return `${hoursDiff} h ${minutesDiff % 60}m ago`;
    }
  } else if (daysDiff < 7) {
    return `${daysDiff} d ago`;
  } else {
    const weeksDiff = Math.floor(daysDiff / 7);
    return `${weeksDiff} w ago`;
  }
};

export const transcriptionDropdown = (review_date) => {
  const now = dayjs();
  const review = dayjs(review_date)?.local();
  const diff = now.diff(review, "day");
  if (diff === 0) {
    return `Today - ${review.format("h:mm:a")}`;
  } else if (diff === 1) {
    return `Yesterday - ${review.format("h:mm:a")}`;
  } else if (diff < 7) {
    return `${diff} Days ago - ${review.format("h:mm:a")}`;
  } else if (diff < 30) {
    const weeks = Math.floor(diff / 7);
    return `${weeks} Week${weeks > 1 ? "s" : ""} ago - ${review.format(
      "h:mm:a",
    )}`;
  } else if (diff < 365) {
    const months = Math.floor(diff / 30);
    return `${months} Month${months > 1 ? "s" : ""} ago - ${review.format(
      "h:mm:a",
    )}`;
  } else {
    const years = Math.floor(diff / 365);
    return `${years} Year${years > 1 ? "s" : ""} ago - ${review.format(
      "h:mm:a",
    )}`;
  }
};

export const businessProfileContainer = (review_date) => {
  const now = dayjs();
  const review = dayjs(review_date);
  const diff = now.diff(review, "day");

  if (diff === 0) {
    return `Today `;
  } else if (diff === 1) {
    return `Yesterday `;
  } else if (diff < 7) {
    return `${diff} Days ago `;
  } else if (diff < 30) {
    const weeks = Math.floor(diff / 7);
    return `${weeks} Week${weeks > 1 ? "s" : ""} ago `;
  } else if (diff < 365) {
    const months = Math.floor(diff / 30);
    return `${months} Month${months > 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(diff / 365);
    return `${years} Year${years > 1 ? "s" : ""} ago `;
  }
};

export const checkIfUnixTimeIsToday = (unixTime) => {
  // Convert Unix time to a Date object
  const inputDate = new Date(unixTime * 1000); // Unix time is in seconds, so multiply by 1000
  const today = new Date();

  // Compare the year, month, and day
  return (
    inputDate.getUTCFullYear() === today.getUTCFullYear() &&
    inputDate.getUTCMonth() === today.getUTCMonth() &&
    inputDate.getUTCDate() === today.getUTCDate()
  );
};
