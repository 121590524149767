import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { formatTimeTo12Hour } from "../../utils/DateTimeFormat";
import locationdark from "../../assets/icons/locationdark.svg";
import {
  customTimeFilter,
  formatGoogleBusinessTime,
} from "../../utils/numberFormat";
import location from "../../assets/icons/location.svg";

export default function LocationHoursCard(props) {
  const { businessDetailsData } = props || {};
  const { data } = businessDetailsData || {};
  const addressDetails = data?.business_locations || [];
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/edit-business/${data?.id}`);
  };

  return (
    <>
      {addressDetails.length === 0 ? (
        <>
          <div className="flex flex-col items-between justify w-full">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <p className="text-gray-500 dark:text-gray-300 text-sm">
                  We currently don{"'"}t have any locations for this business.
                  <br />
                  Add a location to help the community.
                </p>
              </div>
              <img
                src={locationdark}
                alt="SVG icon"
                style={{ width: "74px", height: "74px", opacity: 0.75 }}
                className="ml-4"
              />
            </div>

            <div
              className="mt-2"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                fontSize: "13px",
              }}
            >
              <button
                onClick={handleEditClick}
                className="bg-white uppercase text-black font-semibold border  hover:bg-inherit hover:font-semibold hover:border-2 border-black dark:hover:border-white hover:text-black dark:hover:text-white rounded-full min-h-[45px] px-8 py-2"
              >
                Add location
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="text-sm rounded-lg min-w-[240px] min-h-[210px] flex flex-col flex-grow overflow-y-auto border-[1px] dark:border-darkModeBorder">
          <div className="p-4">
            {/** Map each businessLocation and its woring hours */}
            {addressDetails.map((data, index) => {
              const workingDays =
                data?.business_working_days?.[0]?.google_working_days ||
                data?.business_working_days ||
                [];
              const customDate =
                workingDays?.[0]?.days && customTimeFilter(workingDays);
              return (
                <>
                  <div key={data?.id} className=" py-2">
                    <h1 className="font-semibold ">{data?.address || "--"}</h1>
                    <p className="text-[13px] text-gray-400 py-2 font-semibold">
                      {Math.round(data?.distance * 100) / 100 || 0} miles
                    </p>
                  </div>
                  {typeof workingDays?.[0] === "string" ? (
                    <>
                      {formatGoogleBusinessTime(workingDays)?.map(
                        (data, index) => (
                          <div
                            className="flex items-center justify-between mt-2"
                            key={index}
                          >
                            <p className="font-semibold">
                              {data?.split(":")[0]}
                            </p>
                            <p className="uppercase">
                              {data?.substring(data?.indexOf(":") + 1)}
                            </p>
                          </div>
                        ),
                      )}
                    </>
                  ) : Array.isArray(customDate) && customDate?.length != 0 ? (
                    formatGoogleBusinessTime(customDate)?.map((data, index) => (
                      <div
                        className="flex items-center justify-between mt-2"
                        key={index}
                      >
                        <p className="font-semibold">{data?.split(":")[0]}</p>
                        <p className="uppercase">
                          {data
                            ?.substring(data?.indexOf(":") + 1)
                            ?.split("–")[0]
                            ? formatTimeTo12Hour(
                                data
                                  ?.substring(data?.indexOf(":") + 1)
                                  ?.split("–")[0] + ":00",
                              ) +
                              " – " +
                              formatTimeTo12Hour(
                                data
                                  ?.substring(data?.indexOf(":") + 1)
                                  ?.split("–")[1] + ":00",
                              )
                            : "CLOSED"}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-between py-1 mt-3"></div>
                  )}
                  {index !== addressDetails.length - 1 && (
                    <div className="w-full h-[1px] font-light bg-gray-200 my-1 mb-4 dark:bg-darkModeBG"></div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
