import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import Loader from "../../atom/loader/Loader";
import { VaultsService } from "../../../services/vaults/VaultsService";
import { PRO_USER } from "../../../utils/constants";
import { getAccountType, getCompanyId } from "../../../routes/ProtectedRoutes";
import PropTypes from "prop-types";

/**
 * Create new vault Item - for both business and user vaults
 * */
const AddVaultItem = ({ onSave, businessDetails, vault }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vaultData, setVaultData] = useState([{ ...vault }] || null);
  const { createVaultApi, listAllVaultsApi, createVaultItemApi } =
    VaultsService();
  const checkValidation = useRef(false);
  const accountType = getAccountType();
  const companyId = accountType === PRO_USER ? getCompanyId() : null;

  // schema to validate AddNewVaultItem form
  const schema = yup.object().shape({
    item_name: yup
      .string()
      .min(2, "Please enter the min 3 letter")
      .max(50, "Please enter the max 50 letter")
      .required("This field is required."),
    item_value: yup
      .string()
      .min(2, "Please enter the min 3 letter")
      .required("This field is required."),
  });

  // fetch vault details
  const fetchVaultData = async () => {
    try {
      const queryParams = {
        // send company_id only for professional user
        ...(accountType === PRO_USER && { companyId: companyId }),
        ...(businessDetails?.id && { businessId: businessDetails?.id }),
      };
      const response = await listAllVaultsApi(queryParams);
      // update vaultData state
      setVaultData(response);
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  useEffect(() => {
    if (!vault) {
      fetchVaultData();
    }
  }, [vault, businessDetails]);

  // create new vaultItem - when save button is clicked
  const handleSubmit = (formData) => {
    //set loading
    setIsLoading(true);

    // generate API request body
    const vaultDetails = {
      name: businessDetails?.name,
      business_id: businessDetails?.id,
      ...(accountType === PRO_USER && { company_id: companyId }),
      vault_items: [
        {
          item_name: formData?.item_name,
          item_value: formData?.item_value,
        },
      ],
    };

    // if vault already exists, create vaultItem only
    if (vaultData?.length > 0) {
      createVaultItemApi(vaultData[0].id, {
        item_name: formData?.item_name,
        item_value: formData?.item_value,
      })
        .then(() => {
          toast.success("Vault item created successfully!");
          setIsLoading(false);
          onSave();
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(
            `${error?.response?.data?.message || "Error creating vault item!"}`,
          );
          console.error(
            `Error creating vault item: ${error || "Unknown error"}`,
          );
        });
    } else {
      // create vault alongwith VaultItem, if vault doesn't exist
      createVaultApi(vaultDetails)
        .then(() => {
          toast.success("Vault created successfully!");
          setIsLoading(false);
          onSave();
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(
            `${error?.response?.data?.message || "Business not found"}`,
          );
          console.error(`Error creating vault: ${error || "Unknown error"}`);
        });
    }
  };

  //to handle form
  const formik = useFormik({
    initialValues: { item_name: "", item_value: "" },
    validationSchema: schema,
    onSubmit: handleSubmit,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validateOnChange: checkValidation.current ? true : false,
  });

  return (
    <div className="p-4 relative">
      <div className=" h-full">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col justify-between h-full p-2 "
        >
          <div>
            {/** Field vaultItem name */}
            <div>
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="item_name"
              >
                Title *
              </label>
              <input
                id="item_name"
                type="text"
                autoComplete="off"
                placeholder="Type here"
                value={formik?.values?.item_name}
                onChange={(event) => {
                  formik.setFieldValue("item_name", event.target.value);
                }}
                className={`w-[420px] mt-2 p-2 border mb-2 rounded-md dark:bg-darkModeMain ${
                  formik.errors.item_name && formik.submitCount > 0
                    ? "border-red-500"
                    : "border-gray-200 dark:border-darkModeBorder"
                }`}
              />
              {formik.errors.item_name && formik.submitCount > 0 && (
                <p className="text-red-600 text-xs mt-1">
                  {formik.errors.item_name}
                </p>
              )}
            </div>
            {/** Field vaultItem value */}
            <textarea
              id="item_value"
              placeholder="Type here"
              value={formik?.values?.item_value}
              onChange={(event) => {
                formik.setFieldValue("item_value", event.target.value);
              }}
              onBlur={formik.handleBlur}
              className={`w-full p-2 h-[300px]  mt-4 border rounded-md  dark:bg-darkModeMain overflow-y-auto ${
                formik.errors.item_value && formik.submitCount > 0
                  ? "border-red-500"
                  : "border-gray-200 dark:border-darkModeBorder"
              }`}
            />
            {formik.errors.item_value && formik.submitCount > 0 && (
              <p className="text-red-600 text-xs mt-1">
                {formik.errors.item_value}
              </p>
            )}
          </div>
          {/** Save button */}
          <button
            type="submit"
            className="m-4 h-[45px]  w-[260px] uppercase font-semibold  rounded-full !bg-ipurple text-white hover:!bg-inherit hover:border-2 hover:border-ipurple hover:text-ipurple"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
          {isLoading && <Loader />}
        </form>
      </div>
    </div>
  );
};

AddVaultItem.propTypes = {
  onSave: PropTypes.func.isRequired,
  businessDetails: PropTypes.object,
  vault: PropTypes.object,
};

export default AddVaultItem;
