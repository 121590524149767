import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { IoIosClose } from "react-icons/io";
import countryData from "../../utils/country-code/countrycode.json";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import { UsPhoneNumberValidation } from "../../utils/validate/NumberValidation";

export default function PhoneInput(props) {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const {
    countryCode,
    setCountryCode,
    phoneNumber,
    setPhoneNumber,
    setCountryShortCode,
  } = props || {};
  // Static selection for demonstration, remove dynamic selection logic

  const { ProfileDetails } = AuthPersonalPhone((state) => state);
  const [selectedCountry, setSelectedCountry] = useState(
    countryData.find((country) => country.dial_code === "+1") || countryData[0],
  );
  // Keep UI state for dropdown visibility
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  useEffect(() => {
    const country = countryData.find(
      (country) => country.dial_code === countryCode,
    );
    if (country) setSelectedCountry(country);
    if (country) setCountryShortCode(country.code);
  }, [countryCode]);

  useEffect(() => {
    if (ProfileDetails) {
      setCountryCode(ProfileDetails.country_code);
      setPhoneNumber(UsPhoneNumberValidation(ProfileDetails.phone_number));
    }
  }, [ProfileDetails]);

  const selectCountry = (country) => {
    setCountryCode(country.dial_code);
    setCountryShortCode(country?.code || "US");
    setIsDropdownVisible(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="flex flex-col w-full text-sm ">
      <div className="relative w-[350px]  flex text-sm font-semibold border outline-none dark:border-darkModeBorder rounded-md shadow-sm focus:outline-none focus:ring-none focus:ring-rounded focus:ring-transparent">
        <div
          className="flex items-center w-[100px] bg-transparent border-0 rounded-md outline-transparent focus:outline-none"
          onClick={toggleDropdown}
        >
          <span className="py-0 pl-3 text-2xl">{selectedCountry?.emoji}</span>
          <input
            type="text"
            id="country-code"
            value={countryCode}
            name="country-code"
            className="h-[55px] placeholder:text-gray-400 dark:placeholder:text-slate-400 text-sm py-0 pr-8  z-30 font-semibold w-[85px] bg-transparent border-0 focus:ring-inset-0 focus:ring-indigo-600"
            placeholder="Country-code"
            onChange={(e) => setCountryCode(e.target.value)}
          />
          <button className="absolute left-[85px] z-40" ref={buttonRef}>
            {isDropdownVisible ? (
              <HiChevronUp size={20} />
            ) : (
              <HiChevronDown size={20} />
            )}
          </button>
          <div className="w-[1px] h-[35px] absolute left-[118px] dark:bg-darkModeBorder bg-gray-200"></div>
        </div>
        {isDropdownVisible && (
          <div
            className="absolute z-10 overflow-auto border border-gray-200 rounded-md dark:border-darkModeBorder bg-inherit mt-14 max-h-60"
            ref={dropdownRef}
          >
            {countryData.map((country) => (
              <div
                key={country.code}
                className={`flex items-center p-2 cursor-pointer ${
                  selectedCountry.code !== country.code
                    ? "hover:bg-gray-100 dark:hover:bg-darkModeBG"
                    : ""
                }`}
                onClick={() => selectCountry(country)}
              >
                <span className="mr-2 text-2xl">{country.emoji}</span>
                {country.name} ({country.dial_code})
              </div>
            ))}
          </div>
        )}
        <input
          type="text"
          name="phone-number"
          autoComplete="off"
          id="phone-number"
          className="ml-6 text-sm border-0 rounded-md bg-inherit  placeholder:text-gray-900 dark:placeholder:text-gray-500"
          placeholder="(555) 987-6543"
          value={phoneNumber}
          onChange={(event) => {
            setPhoneNumber(UsPhoneNumberValidation(event.target.value));
          }}
        />
        <IoIosClose
          size={24}
          className="absolute transform -translate-y-1/2 cursor-pointer right-2 top-1/2"
          onClick={() => {
            setPhoneNumber("");
          }}
        />
      </div>
    </div>
  );
}
