import { create } from "zustand";
import AxiosApi from "../../../api/requestInterceptor";
import { BASE_URL, deviceDetails } from "../../../utils/constants";
import axios from "axios";
import { extensionLogin } from "../../../utils/DeviceDetails/SipDetails";
import { clearLocalStorage } from "../../../utils/common";
import { updateProfile } from "../../profileService";

// This module handles the authentication process using personal phone numbers.

// Exporting a function that creates the authentication store
export const AuthPersonalPhone = create((set) => ({
  // Initial state variables
  PhoneLoginDetails: {}, // Stores details after successful phone login
  emailLoginDetails: {}, // Stores details after successful phone login
  VerficationCode: {}, // Placeholder for verification code
  ProfileDetails: {},
  imageUpload: {},
  resetPassword: {},
  callSentDetails: {},
  invitedFriendsData: [], //Stores list of invited friends
  message: "",
  loadingEmailVerify: false,
  loading: false, // Indicates whether an API request is in progress
  invitedFriendsListLoading: false,
  error: null, // Holds any error that occurs during the API request

  // Async function to initiate phone login API request
  PhoneLoginApi: async (userData) => {
    try {
      // Set loading state to true to indicate the start of the API request
      set({ loading: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the authentication API with user details
      const response = await AxiosApi.post(
        `${BASE_URL}auth/phone-verify`,
        userData,
        {
          headers: {
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
          },
        },
      );

      // Extract relevant data from the API response

      let { success, message, data } = response?.data || {};
      if (success) {
        // Update state with the received data and set loading to false
        set({ loading: false, PhoneLoginDetails: data });
        return data;
      } else {
        // If the API request was not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API request, update state with the error and return it
      set({ loading: false, error: err });
      return err;
    }
  },

  // Async function to initiate auth-phone verification via call
  verifyPhoneByCallApi: async (userData) => {
    try {
      // Set loading state to true to indicate the start of the API request
      set({ loading: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the authentication API with user details
      const response = await AxiosApi.post(
        `${BASE_URL}auth/call-verify`,
        userData,
        {
          headers: {
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
          },
        },
      );

      // Extract relevant data from the API response

      let { success, message, data } = response?.data || {};
      if (success) {
        // Update state with the received data and set loading to false
        set({ loading: false, callSentDetails: data });
        return { success: success, message: message, data: data };
      } else {
        set({ loading: false });
        // If the API request was not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API request, update state with the error and return it
      set({ loading: false, error: err });
      throw err?.response?.statusText || err?.message || err;
    }
  },
  // Async function to initiate password login API request
  PasswordLoginApi: async (userData) => {
    try {
      // Set loading state to true to indicate the start of the API request
      set({ loading: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the authentication API with user details
      const response = await AxiosApi.post(`${BASE_URL}auth/login`, userData, {
        headers: {
          "device-id": DeviceData?.deviceID,
          "device-name": DeviceData?.deviceName,
        },
      });

      // Extract relevant data from the API response

      let { success, message, data } = response?.data || {};

      if (success) {
        // Update state with the received data and set loading to false
        set({ loading: false, emailLoginDetails: data });
        return data;
      } else {
        // If the API request was not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API request, update state with the error and return it
      set({ loading: false, error: err });
      return err;
    }
  },

  // Async function to verify the confirmation code sent during phone login
  verifyConfirmationCode: async (userDetails) => {
    try {
      set({ isLoading: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.post(
        `${BASE_URL}auth/phone-confirm-code`,
        userDetails,
        {
          headers: {
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
          },
        },
      );

      let { success, message, data } = response?.data || {};

      if (success) {
        // Update state with the received verification code data and set loading to false
        set({ isLoading: false, VerficationCode: data });
        return data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },

  // Async function to verify the confirmation code sent during phone login via call
  verifyCallConfirmationCode: async (userDetails) => {
    try {
      set({ isLoading: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.post(
        `${BASE_URL}auth/call-confirm-code`,
        userDetails,
        {
          headers: {
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
          },
        },
      );

      let { success, message, data } = response?.data || {};

      if (success) {
        // Update state with the received verification code data and set loading to false
        set({ isLoading: false, VerficationCode: data });
        return data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },

  //email-confirm-code
  // Async function to verify the confirmation code sent during phone login
  verifyEmailConfirmationCode: async (userDetails) => {
    try {
      set({ loadingEmailVerify: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.post(
        `${BASE_URL}auth/email-confirm-code`,
        userDetails,
        {
          headers: {
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
          },
        },
      );

      let { success, message, data } = response?.data || {};

      if (success) {
        // Update state with the received verification code data and set loading to false
        set({ loadingEmailVerify: false, VerficationCode: data });
        return data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loadingEmailVerify: false, error: err });
      return err;
    }
  },

  verifyEmailConfirmationCodePersonal: async (userDetails) => {
    try {
      set({ loadingEmailVerify: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.post(
        `${BASE_URL}auth/verify-code-email`,
        userDetails,
        {
          headers: {
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
          },
        },
      );

      let { success, message, data } = response?.data || {};

      if (success) {
        // Update state with the received verification code data and set loading to false
        set({ loadingEmailVerify: false, VerficationCode: data });
      } else {
        throw message;
      }
      return response;
    } catch (err) {
      set({ loadingEmailVerify: false, error: err });
      return err;
    }
  },
  ResendVerifyConfirmationCode: async (userDetails) => {
    try {
      set({ isLoading: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.post(
        `${BASE_URL}auth/phone-resend-code`,
        userDetails,
        {
          headers: {
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
          },
        },
      );

      let { success, message, data } = response?.data;
      if (success) {
        // Update state with the received verification code data and set loading to false
        set({ isLoading: false, VerficationCode: data });
        return data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },

  NewProfileSet: async (userDetails, tokenDetails) => {
    try {
      set({ isLoading: true });
      const DeviceData = deviceDetails();
      // Make a POST request to the API endpoint for OTP verification
      const response = await axios.post(
        `${BASE_URL}auth/profile`,
        userDetails,
        {
          headers: {
            Authorization: `Bearer ${tokenDetails}`,
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
          },
        },
      );
      let { success, message, data } = response?.data;
      if (
        success &&
        (message != "Email has already been taken." ||
          message != "Unauthorized")
      ) {
        // Update state with the received verification code data and set loading to false
        set({ isLoading: false, ProfileDetails: data });
        return true;
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },
  getProfileDetails: async () => {
    const { DeviceData } = deviceDetails();
    try {
      set({ isLoading: true });
      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.get(`${BASE_URL}auth/profile`, {
        headers: {
          "device-id": DeviceData?.deviceID,
          "device-name": DeviceData?.deviceName,
        },
      });
      let { success, message, data } = response?.data;
      if (typeof data == "object" && data) {
        set({ isLoading: false, ProfileDetails: data });
        localStorage.setItem("userDetails", JSON.stringify(data));
        return true;
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },
  updateCreatedProfile: async (data) => {
    const { userDetails, token, id } = data;
    try {
      set({ isLoading: true });
      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.post(
        `${BASE_URL}auth/profile`,
        userDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        },
      );
      let { success, message } = response?.data;

      if (typeof data == "object" && success) {
        set({ isLoading: false });
        localStorage.setItem("userDetails", JSON.stringify(data));
        return true;
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },

  //created this function to fetch data for creating account in PersonalCreateProfile.jsx

  fetchProfileDetails: async (data) => {
    if (!data || !data.token) {
      console.error("Token is missing");
      return;
    }
    const { token } = data;
    const { DeviceData } = deviceDetails();
    try {
      set({ isLoading: true });
      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.get(`${BASE_URL}auth/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "device-id": DeviceData?.deviceID,
          "device-name": DeviceData?.deviceName,
        },
      });
      let { success, message, data } = response?.data;
      if (typeof data == "object" && data) {
        set({ isLoading: false, ProfileDetails: data });
        localStorage.setItem("userDetails", JSON.stringify(data));
        return true;
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },
  NewProfileImageUpload: async (formData, tokenDetails) => {
    try {
      set({ isLoading: true });
      // Make a POST request to the API endpoint for OTP verification
      const DeviceData = deviceDetails();
      const response = await axios.post(
        `${BASE_URL}users/upload-image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokenDetails}`,
            "device-id": DeviceData?.deviceID,
            "device-name": DeviceData?.deviceName,
            "Content-Type": "multipart/form-data",
          },
        },
      );
      let { success, message, data } = response?.data;
      if (success) {
        // Update state with the received verification code data and set loading to false
        set({ isLoading: false, imageUpload: data });
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },
  LogoutApi: async () => {
    try {
      set({ isLoading: true });

      // Make a POST request to the API endpoint for OTP verification
      const response = await AxiosApi.post(`${BASE_URL}auth/logout`);

      let { success, message, data } = response?.data;
      if (success) {
        // Update state with the received verification code data and set loading to false
        set({
          isLoading: false,
          ProfileDetails: {},
          PhoneLoginDetails: {}, // Stores details after successful phone login
          VerficationCode: {}, // Placeholder for verification code
        });
        clearLocalStorage();
        return true;
      } else {
        throw message;
      }
    } catch (err) {
      localStorage.clear();
      set({ isLoading: false, error: err });
      return err;
    }
  },

  ForgotPasswordApi: async (emailDetails) => {
    try {
      set({ isLoading: true });

      const response = await axios.post(
        `${BASE_URL}auth/forgot-password`,
        emailDetails,
      );
      let { success, message, data } = response?.data;

      if (success) {
        return { success: success, message: message };
      } else {
        throw { success: success, message: message };
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },
  ResetPasswordApi: async (userDetails) => {
    try {
      set({ isLoading: true });

      const response = await AxiosApi.post(
        `${BASE_URL}auth/reset-password`,
        userDetails,
      );
      let { success, message, data } = response?.data;
      if (success) {
        return { success: success, message: message };
      } else {
        return { success: success, message: message };
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },
  setPasswordApi: async (userDetails) => {
    try {
      set({ isLoading: true });

      const response = await AxiosApi.post(
        `${BASE_URL}auth/set-password`,
        userDetails,
      );
      let { success, message, data } = response?.data;
      if (success) {
        return { success: success, message: message };
      } else {
        return { success: success, message: message };
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },
  ResetConfirmCodeApi: async (userDetails) => {
    try {
      set({ isLoading: true });

      const response = await AxiosApi.post(
        `${BASE_URL}auth/confirm-reset-password`,
        userDetails,
      );

      let { success, message, data } = response?.data || { message: "error" };
      return response?.data;
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },

  sipAuthLoginApi: async () => {
    try {
      set({ isLoading: true });
      const response = await AxiosApi.post(`${BASE_URL}auth/sip-login`);
      let { data } = response?.data || {};
      let { sipp } = data || {};
      localStorage.setItem("sipDetails", sipp);
      // Check if extension is installed, login on extension
      extensionLogin();
      return data;
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },

  /**
   * -------- Invite friends API services STARTS here --------
   */

  inviteFriendsCreateApi: async (inviteDetails) => {
    try {
      set({ loading: true });

      const response = await AxiosApi.post(
        `${BASE_URL}users/invites`,
        inviteDetails,
      );
      let { success, message, data } = response?.data;
      if (success) {
        set({ loading: false });
        return { success, message, data };
      } else {
        set({ loading: false });
        throw new Error(message || "An error occurred while inviting friends.");
      }
    } catch (err) {
      set({ loading: false });
      return {
        success: false,
        message: err?.response?.data?.message || err.message || "Unknown error",
      };
    }
  },
  listInvitedFriendsApi: async (queryParams = {}) => {
    try {
      set({ invitedFriendsListLoading: true });
      const query = new URLSearchParams(queryParams).toString();

      const response = await AxiosApi.get(
        `${BASE_URL}users/invites${query ? `?${query}` : ""}`,
      );
      let { success, message, data } = response?.data;
      if (success) {
        set({ invitedFriendsListLoading: false, invitedFriendsData: data });
        return { success, message, data };
      } else {
        set({ invitedFriendsListLoading: false });
        throw new Error(
          message || "An error occurred while listing invited friends.",
        );
      }
    } catch (err) {
      set({ invitedFriendsListLoading: false });
      return {
        success: false,
        message: err?.response?.data?.message || err.message || "Unknown error",
      };
    }
  },
  resendInviteFriendsApi: async (id) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}users/invites/${id}/resend`,
      );
      let { success, message } = response?.data;
      if (success) {
        return { success, message };
      } else {
        throw new Error(message || "An error occurred while inviting friends.");
      }
    } catch (err) {
      return {
        success: false,
        message: err?.response?.data?.message || err.message || "Unknown error",
      };
    }
  },
  inviteFriendsUpdateApi: async (id, inviteDetails) => {
    try {
      const response = await AxiosApi.put(
        `${BASE_URL}users/invites/${id}`,
        inviteDetails,
      );
      let { success, message } = response?.data;
      if (success) {
        return { success, message };
      } else {
        throw new Error(message || "An error occurred while updating invite.");
      }
    } catch (err) {
      return {
        success: false,
        message: err?.response?.data?.message || err.message || "Unknown error",
      };
    }
  },
  /**
   * -------- Invite friends API services ENDS here ---------
   */
}));
