import logo from "../../assets/logo.webp";
import SearchInput from "../search-results/SearchInput";
import Avatar from "../Avatar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Accordian from "./Accordian";
import { userDetails } from "../../routes/ProtectedRoutes";
import { useSearch } from "../../context/SearchContext";
import { useEffect, useState } from "react";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import pusherNotification from "../pusher/pusherNotification";
import { deviceId } from "../../utils/DeviceDetails/DeviceDetails";
import NotificationService from "../../services/notification/NotificationService";
import { GoBell } from "react-icons/go";
import { TETooltip } from "tw-elements-react";
import { sendMixpanelEvent } from "../../services/mixpanelService";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import CallPro from "../../assets/icons/CallPro.svg";
import User from "../../assets/icons/User.svg";
import { checkIfUnixTimeIsToday } from "../../utils/TimeDetails";
import toast from "react-hot-toast";
import { checkExtensionStatus } from "../atom/checkExtension";
import { extensionLogin } from "../../utils/DeviceDetails/SipDetails";

export default function TopNavBar() {
  const { fetchOngoingCall, holdCall, returnToCall } = CallDetailsService(
    (state) => state,
  );
  const { ProfileDetails, getProfileDetails } = AuthPersonalPhone(
    (state) => state,
  );

  const location = useLocation();
  const { getlistNotificationApi, unReadNotificationTotal } =
    NotificationService((state) => state);
  const { setSearchTerm, setSelectedLocation, setSearchViewToggle } =
    useSearch();
  const [activeCall, setActiveCall] = useState(false);
  const [activeCallHistory, setActiveCallHistory] = useState({});
  const [loading, setLoading] = useState(false);
  const locationName = location?.pathname?.substring(1);
  const deviceUid = deviceId();

  const profileButtonClass =
    locationName === "profile"
      ? " flex items-center justify-center h-20 bg-white dark:bg-darkModeMain "
      : "";
  const navigate = useNavigate();
  const userDetailsProfile = userDetails();

  useEffect(() => {
    userDetails();
  }, [ProfileDetails]);

  const handleNotificationsClick = () => {
    sendMixpanelEvent("Inbox Viewed");
    navigate("/notifications");
  };

  // Fetch ongoing calls. Show active call button if there is an ongoing call
  function fetchActiveCalls() {
    fetchOngoingCall().then((data) => {
      if (data && data.length > 0) {
        const activeCallRecord = data[0];
        const isActiveCallFromToday = checkIfUnixTimeIsToday(
          activeCallRecord?.call_started_at,
        );

        if (isActiveCallFromToday) {
          setActiveCallHistory(data[0]);
          setActiveCall(true);
        }
      } else {
        setActiveCallHistory({});
        setActiveCall(false);
      }
    });
  }

  useEffect(() => {
    getProfileDetails();

    // Show active call button if there is an ongoing call
    fetchActiveCalls();
  }, []);

  const getNotificationCount = () => {
    getlistNotificationApi(
      {
        page: 1,
        businessId: "",
        start_date: "",
        end_date: "",
        is_archived: false,
        limit: "",
      },
      "all",
    );
  };

  useEffect(() => {
    const channel = pusherNotification.subscribe(deviceUid);
    getNotificationCount();
    const eventHandlers = {
      "email.confirm_code_sent": () => {
        getNotificationCount();
      },
      "call.end_on_hold": () => {
        getNotificationCount();
      },
      "business.create_contact": () => {
        getNotificationCount();
      },
      "business.update_contact": () => {
        getNotificationCount();
      },
      "business.create_location": () => {
        getNotificationCount();
      },
      "business.update_location": () => {
        getNotificationCount();
      },
      "business.create": () => {
        getNotificationCount();
      },
      "business.update": () => {
        getNotificationCount();
      },
      "business.create_working_days": () => {
        getNotificationCount();
      },
      "business.update_working_days": () => {
        getNotificationCount();
      },
      "call_request.create": () => {
        getNotificationCount();
      },
      "call_request.update": () => {
        getNotificationCount();
      },
      "reminder.create": () => {
        getNotificationCount();
      },
      "reminder.update": () => {
        getNotificationCount();
      },
      "business.report": () => {
        getNotificationCount();
      },
      "call-connected": () => {
        setLoading(false);
      },
      "call-request-started": () => {
        setActiveCall(true);
        fetchActiveCalls();
      },
      "call-sip-started": () => {
        setActiveCall(true);
        fetchActiveCalls();
      },
      "call-started": () => {
        setActiveCall(true);
        fetchActiveCalls();
      },
      "call-ended": () => {
        setActiveCall(false);
        fetchActiveCalls();
      },
    };
    Object.keys(eventHandlers).forEach((event) => {
      channel.bind(event, eventHandlers[event]);
    });

    return () => {
      channel.unbind(); // Unbind all callbacks for this channel
    };
  }, [deviceUid]);

  async function connectActiveCall() {
    if (!activeCallHistory) return;
    const isExtensionInstalled = localStorage.getItem("isExtension");
    if (isExtensionInstalled) {
      extensionLogin();
    } else {
      checkExtensionStatus();
      return;
    }
    setLoading(true);

    try {
      if (
        (activeCallHistory.request_call &&
          activeCallHistory.child_call_status != "IN-PROGRESS") ||
        (activeCallHistory.is_sip &&
          activeCallHistory.call_status != "IN-PROGRESS")
      ) {
        // If request call/SIP call, business call is active and child call is not active, return to call
        await returnToCall(activeCallHistory.id);
      } else if (
        (activeCallHistory.request_call &&
          activeCallHistory.child_call_status == "IN-PROGRESS") ||
        (activeCallHistory.is_sip &&
          activeCallHistory.call_status == "IN-PROGRESS")
      ) {
        // If request call/SIP call, business call is active and child call is also active,
        // hold call and then return to call
        await holdCall(activeCallHistory.id);
        await returnToCall(activeCallHistory.id);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to call:", error);
      // Display an error message
      toast.error("Error processing the request");
    } finally {
      setTimeout(() => {
        setLoading(false);
        fetchActiveCalls();
      }, 10000);
    }
  }

  return (
    <div className="fixed bg-white dark:bg-darkModeMain flex h-[80px] z-40 justify-between w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder items-center">
      <div className="flex flex-row items-center pr-6">
        <img
          src={logo}
          alt="logo"
          width={106}
          height={50}
          // height={auto}
          className="ml-[9px] cursor-pointer"
          draggable={false}
          onClick={() => {
            setSearchViewToggle(false);
            navigate("/directory/search");
          }}
        />
      </div>
      <SearchInput />
      <div className="flex items-center h-full">
        {/* Call Active Button  */}
        {activeCall && (
          <div className="absolute-right-0 ml-1 w-full max-w-[126px] h-[36px] flex justify-end items-center text-nowrap">
            <button
              className={`flex items-center gap-[5px] px-4 py-0 rounded-full text-white ${
                loading ? "bg-gray-400" : "call-active-btn"
              }`}
              style={{ width: "125px", height: "35px" }} // Set fixed dimensions
              onClick={() => connectActiveCall()}
            >
              <img src={CallPro} alt="Call Icon" />
              <span
                className={`text-white font-semibold text-[12px] leading-[14px] ${
                  loading ? "text-gray-200" : ""
                }`}
              >
                {loading ? "Connecting" : "Call Active"}
              </span>
            </button>
          </div>
        )}
        <div className="relative flex flex-row items-center gap-3 mx-4 mt-2">
          <button id="step-10" onClick={handleNotificationsClick}>
            <TETooltip placement="right" title={"Notification"}>
              <GoBell className="hover:cursor-pointer" size={28} />
              {unReadNotificationTotal == 0 || unReadNotificationTotal < 0 ? (
                ""
              ) : (
                <div className="absolute border-[1px]  border-gray-100  flex text-white justify-center  shadow-xl shadow-black  items-center text-[8px] right-0 -top-1 rounded-full w-[15px] font-semibold bg-ipink h-[15px]">
                  {unReadNotificationTotal > 9 ? "9+" : unReadNotificationTotal}
                </div>
              )}
            </TETooltip>
          </button>
        </div>
        <div className="h-[30px]  bg-gray-100 w-[2px] dark:bg-darkModeBorder"></div>
        {userDetailsProfile && (
          <div
            className={`flex items-center pl-3 justify-center mr-2 ${profileButtonClass}`}
          >
            <button className="rounded-full ">
              <NavLink
                to="/profile"
                onClick={() => {
                  setSearchTerm("");
                  setSelectedLocation("");
                }}
              >
                <Avatar
                  pathSrc={userDetailsProfile?.upload?.file_path || User}
                />
              </NavLink>
            </button>
            <Accordian />
          </div>
        )}
      </div>
    </div>
  );
}
