import { useState, useRef } from "react";
import { SlLocationPin } from "react-icons/sl";
import UpdateBusDetails from "./edit-views/UpdateBusDetails";
import { BusinessIcon, ErrorIcon, TickIcon } from "../icons/Icons";
import { CallIcon } from "../icons/CallIcon";
import ReportPopup from "../business-profile/ReportPopup";
import { useFormik } from "formik";
import { BusinessService } from "../../services/bussiness/BusinessService";
import Contacts from "./Contacts";
import UpdateLocationHoursView from "./edit-views/UpdateLocationHoursView";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getUserId } from "../../routes/ProtectedRoutes";
import PropTypes from "prop-types";
import { removeEmptyStringValues } from "../../utils/common";
import { sendMixpanelEvent } from "../../services/mixpanelService";

EditExistingContainer.propTypes = {
  initialValues: PropTypes.any,
};

export default function EditExistingContainer() {
  const [activeView, setActiveView] = useState("BusinessDetails");
  const {
    createBusinessApi,
    uploadBusinessImage,
    categoryResp,
    businessLoading,
  } = BusinessService((state) => state);
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    chat_id: "",
    category_id: "",
    additional_category_id: "",
    is_private: false,
    business_contacts: [],
    business_locations: [],
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleSubmit = () => {
    const imageData = formik.values?.file;
    const formDetails = new FormData();
    formDetails.append("file", imageData);
    const { category_name, additional_category_name, ...restValues } =
      formik.values;

    let formikData = {
      ...restValues,
      ...(categoryResp?.data?.filter(
        (data) => data?.id == additional_category_name,
      )?.length > 0
        ? { additional_category_id: additional_category_name }
        : {
            additional_category_name: additional_category_name || "",
          }),
      ...(categoryResp?.data?.filter((data) => data?.id == category_name)
        ?.length > 0
        ? { category_id: category_name }
        : { category_name: category_name || "" }),
      business_contacts: formik.values?.business_contacts?.map(
        ({ account_type, file, ...rest }) => ({
          ...rest,
          call_avg_minutes: rest?.call_avg_minutes || 0,
        }),
      ),
    };
    delete formikData?.file;

    createBusinessApi(removeEmptyStringValues(formikData)).then(
      async (data) => {
        if (data?.success == false) {
          toast.error(data?.message, {
            icon: <ErrorIcon />,
            style: {
              background: "#2A2B2E",
              color: "white",
              borderRadius: "28px",
            },
          });
        } else if (data?.data?.id) {
          if (formik.values?.file) {
            await uploadBusinessImage(data?.data?.id, formDetails);
          }
          await navigate(`/business/${data?.data?.id}`);
          toast.success("Business created successfully", {
            icon: <TickIcon />,
            style: {
              background: "#2A2B2E",
              color: "white",
              borderRadius: "28px",
            },
          });
        }
      },
    );
  };
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(1, "Please enter the min 3 letter")
      .max(50, "Please enter the max 50 letter")
      .required("This field is required."),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const [showReportPopup, setShowReportPopup] = useState(false);

  const handleClose = () => {
    setShowReportPopup(false);
  };

  const busDetailsRef = useRef("BusinessDetails");
  const contactsRef = useRef(null);
  const locationHoursRef = useRef(null);

  const scrollToView = (ref, viewName) => {
    ref.current.scrollIntoView({ behavior: "smooth" });

    setActiveView(viewName);
  };

  const buttonClass = (viewName) =>
    `w-full pl-4 overflow-hidden flex items-center min-h-[60px] h-[60px] font-semibold gap-2 ${
      activeView === viewName
        ? "bg-lightBg dark:bg-darkModeBG text-ipurple overflow-hidden"
        : ""
    } hover:bg-lightBg dark:hover:bg-darkModeBG overflow-hidden min-h-[60px] h-[60px]`;

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex w-full gap-4 py-8 pl-[100px] overflow-hidden text-sm">
    <div
      style={{ height: "calc(100vh - 180px)" }}
      className="flex w-full gap-4 mt-[126px]"
    >
      <div className="w-1/3 flex max-h-[242px] flex-col bg-white dark:bg-darkModeMain rounded-lg border-[1px] border-gray-100 dark:border-darkModeBorder overflow-hidden">
        <div className="flex gap-3 items-center min-h-[60px] border-b-[1px] border-gray-100 dark:border-darkModeBorder w-full">
          <h1 className="px-4 text-sm font-semibold uppercase">
            Add business to iAllo
          </h1>
        </div>
        <div className="flex flex-col divide-y-[1px] dark:divide-darkModeBorder">
          <button
            onClick={() => scrollToView(busDetailsRef, "BusinessDetails")}
            className={`${buttonClass("BusinessDetails")} overflow-hidden`}
          >
            <div className="w-[30px]">
              <BusinessIcon
                className={
                  activeView === "BusinessDetails"
                    ? "!text-ipurple"
                    : "text-black dark:text-white"
                }
              />
            </div>
            <p
              className={
                activeView === "BusinessDetails"
                  ? "text-ipurple w-[200px] flex justify-start"
                  : "w-[200px] flex justify-start"
              }
            >
              Business Details
            </p>
          </button>
  
          <button
            onClick={() => scrollToView(contactsRef, "Contacts")}
            className={`${buttonClass("Contacts")} overflow-hidden`}
          >
            <div className="w-[30px]">
              <CallIcon
                className={
                  activeView === "Contacts"
                    ? "!text-ipurple dark:text-white"
                    : "text-black dark:text-white"
                }
              />
            </div>
            <p
              className={
                activeView === "Contacts"
                  ? "text-ipurple w-[200px] flex justify-start"
                  : "w-[200px] flex justify-start"
              }
            >
              Contacts
            </p>
          </button>
  
          <button
            onClick={() => scrollToView(locationHoursRef, "LocationHours")}
            className={`${buttonClass("LocationHours")} overflow-hidden`}
          >
            <div className="w-[30px]">
              <SlLocationPin
                size={18}
                className={
                  activeView === "LocationHours"
                    ? "!text-ipurple"
                    : "text-black dark:text-white"
                }
              />
            </div>
            <p
              className={
                activeView === "LocationHours"
                  ? "text-ipurple w-[200px] flex justify-start"
                  : "w-[200px] flex justify-start"
              }
            >
              Location & Working Hours
            </p>
          </button>
        </div>
      </div>
  
      <div className="flex flex-col w-2/3 mr-4 overflow-x-hidden overflow-y-scroll">
        <div className="z-20 flex items-center w-full mr-4 overflow-hidden"></div>
        <div className="flex flex-col space-y-4">
          <div
            ref={busDetailsRef}
            className="w-full bg-white rounded-lg border-[1px] border-gray-100 dark:bg-darkModeMain dark:border-darkModeBorder"
          >
            <div className="min-h-[60px] max-h-[60px] flex items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeMain">
              <h1 className="px-4 text-sm font-semibold uppercase">
                Business Details
              </h1>
            </div>
            <UpdateBusDetails handleChange={handleChange} formik={formik} />
          </div>
          <div
            ref={contactsRef}
            className="w-full bg-white rounded-lg border-[1px] border-gray-100 dark:bg-darkModeMain dark:border-darkModeBorder"
          >
            <Contacts
              handleChange={handleChange}
              formik={formik}
              errors={formik.errors}
            />
          </div>
          <div ref={locationHoursRef}>
            <UpdateLocationHoursView
              handleChange={handleChange}
              formik={formik}
            />
          </div>
        </div>
      </div>
      {showReportPopup && <ReportPopup onClose={handleClose} />}
    </div>
  
    <div className="flex items-center justify-between w-full absolute bottom-0 h-[85px] border-t-[1px] bg-white dark:bg-darkModeMain dark:border-darkModeBorder -ml-8 overflow-hidden">
      <button
        className="mx-8 w-[220px] rounded-full h-[45px] border-2 border-b border-black dark:border-white hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black uppercase font-semibold overflow-hidden"
        type="button"
        onClick={() => {
          navigate(-1);
        }}
      >
        Cancel
      </button>
      <button
        className="mr-28 w-[220px] uppercase font-semibold h-[45px] rounded-full !bg-ipurple text-white hover:!bg-inherit hover:border-2 hover:border-ipurple hover:text-ipurple overflow-hidden"
        type="submit"
        onClick={(e) => {
          sendMixpanelEvent("Business Details Added");
          formik.handleSubmit(e);
        }}
        disabled={businessLoading}
      >
        Save
      </button>
    </div>
  </div>
  
  );
}
