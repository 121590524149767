import { PiPushPin } from "react-icons/pi";
import { LuTrash } from "react-icons/lu";

export default function NotesMenu(props) {
  let { onRemove, onPinNote, pinId, dropdownRef } = props || {};

  return (
    <div
      ref={dropdownRef}
      className="absolute top-6 right-0 flex text-[13px] font-semibold flex-col overflow-hidden w-[200px] h-[80px] bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100  shadow-xl dark:border-darkModeBorder divide-y-[1px] dark:divide-darkModeBorder"
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          onPinNote();
        }}
        className="flex items-center w-full gap-2  z-999 p-3 h-1/2 hover:bg-lightBg dark:hover:bg-darkModeBG dark:text-white"
      >
        <PiPushPin size={20} color="black dark:text-white" />
        <p className="text-black dark:text-white">
          {pinId == false ? "Pin" : "Unpin"}
        </p>
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
        className="flex items-center w-full gap-2  z-999 p-3 h-1/2 hover:bg-lightBg dark:hover:bg-darkModeBG"
      >
        <LuTrash size={20} className="text-black dark:text-white" />
        <p className="text-black dark:text-white">Remove</p>
      </button>
    </div>
  );
}
