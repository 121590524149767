import { useState } from "react";
import { AddBusinessIcon, BusinessIcon } from "../icons/Icons";
import { IoClose } from "react-icons/io5";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import PropTypes from "prop-types";

const userProfile = {
  isVerified: true, // Toggle for testing
  hasProfilePicture: true,
};

ProfilePictureDetails.propTypes = {
  formik: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default function ProfilePictureDetails(props) {
  const { formik, data } = props;
  const { isVerified, hasProfilePicture } = userProfile;
  const [privewImage, setPreviewImage] = useState("");

  // Determine header text based on whether the profile is verified
  // const headerText = isVerified ? "Profile Picture" : "Business Profile Picture";

  return (
    <div className="flex flex-col items-center justify-center w-full py-4 text-center ">
      <h1 className="pb-1">Business Profile Picture</h1>

      <div className="flex flex-col items-center justify-center w-full p-4 space-y-6">
        <div className="border p-4 rounded-3xl h-[134px] w-[134px] flex items-center justify-center dark:border-darkBorder">
          {hasProfilePicture ? (
            <>
              <div className="relative">
                {privewImage ? (
                  <IoClose
                    className="absolute left-20 bottom-20 h-7 w-7 rounded-full p-1 bg-white  dark:bg-darkModeBG"
                    onClick={() => {
                      setPreviewImage("");
                      formik.setFieldValue("file", "");
                    }}
                  />
                ) : (
                  ""
                )}
                {privewImage ? (
                  <img
                    src={privewImage}
                    alt=""
                    className={`w-[90px] h-[90px] border-none`}
                  />
                ) : data?.name ? (
                  <DirectoryImageInitials
                    imageUrl={data?.upload?.file_path}
                    alt={data?.name}
                    name={data?.name}
                    boxColor={data?.business_color?.toLowerCase()}
                  />
                ) : (
                  <AddBusinessIcon className="text-black dark:text-white" />
                )}
              </div>
            </>
          ) : (
            <div className="w-[90px] h-[90px] rounded-lg border-2 dark:border-darkModeBorder text-black dark:text-white  dark:hover:text-black border-gray-100 justify-center items-center flex">
              <BusinessIcon className="w-[50px] h-[50px] text-black" />
            </div>
          )}
        </div>

        {hasProfilePicture ? (
          <button className="relative w-[180px] flex items-center justify-center gap-2 text-[13px] font-[600] h-[45px] min-h-[45px] hover:border-none hover:bg-black border-black dark:hover:bg-white hover:text-white dark:hover:text-black rounded-full border-2 mb-8 border-b border-gray-100lack dark:border-white uppercase bg-inherit">
            <input
              type="file"
              className="absolute opacity-0 w-full"
              name="file"
              onChange={(item) => {
                formik.setFieldValue("file", item?.target?.files?.[0]);
                setPreviewImage(
                  item?.target?.files
                    ? URL.createObjectURL(item?.target?.files?.[0])
                    : ""
                );
              }}
            />
            <div className=" flex justify-center items-center">
              {/* <FileIcon className=" w-[22px] h-[22px] text-black dark:text-white hover:text-white dark:hover:text-black" /> */}
              <p>Upload</p>
            </div>
          </button>
        ) : isVerified ? (
          <button className="w-full text-[13px] font-[600] h-[45px] min-h-[45px] hover:border-none hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black rounded-full border-2 mb-8 border-b border-gray-100lack dark:border-white uppercase bg-inherit">
            Request Update
          </button>
        ) : (
          <button className="w-full text-[13px] font-[600] h-[45px] min-h-[45px] hover:border-none hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black rounded-full border-2 mb-8 border-b border-gray-100lack dark:border-white uppercase bg-inherit">
            Request Update
          </button>
        )}
      </div>
    </div>
  );
}
