import { useState } from "react";
import callUs from "../../assets/CallUs.svg";
import requestCall from "../../assets/RequestCall.svg";
import logo from "../../assets/Iallo.webp";
import { MdAccessTime } from "react-icons/md";
import { sipCallParams } from "../../utils/DeviceDetails/SipDetails";
import { checkExtensionStatus } from "../atom/checkExtension";
export default function ContactBusinessCard(props) {
  const {
    userData,
    setShowRescheduleCallModal,
    handleCancelBtn,
    callInitiate,
    handleCallNow,
    pageType,
  } = props || {};

  const [viewType, setViewType] = useState("beforeCallScheduleView");

  const handleCallClick = async () => {
    if (pageType == "SearchChatMessage") {
      callInitiate();
    } else {
      await callInitiate();
      const sipCallData = sipCallParams(userData?.phone_number);
      window.postMessage(sipCallData, "*");
      checkExtensionStatus();
    }
  };

  const handleScheduleCallClick = () => {
    //for not 5 min later or schedule
    setShowRescheduleCallModal(true);
  };
  const handleRequestCall = () => {
    setViewType("afterCallScheduleView");
  };
  const btnClassName =
    "h-[45px] w-[155px] rounded-full border-[1px]  border-gray-100 text-[12px] font-semibold";
  const buttonBar =
    "w-[180px] mt-16 pt-0 border-[1px]  border-gray-100  border-neutral-200  dark:border-darkModeBorder rounded-lg flex flex-col justify-center items-center text-center";
  return (
    <div
      className={
        viewType == "beforeCallScheduleView"
          ? "w-[400px] min-h-[450px] flex m-2  flex-col  border-[1px]  border-gray-100 dark:border-darkModeBorder rounded-lg text-[black] "
          : "w-[400px] min-h-[180px] flex m-2  flex-col  border-[1px]  border-gray-100 dark:border-darkModeBorder rounded-lg text-[black] "
      }
    >
      <div className="flex flex-col items-center justify-around w-full h-full p-4 !pt-0 text-center relative mt-3">
        <img src={logo} width={120} height={55} alt="iallo logo" className="" />
        <h1 className="font-bold text-[18px] p-0 max-w-[300px] !pt-3 leading-relaxed text-[black] dark:text-white">
          {viewType == "beforeCallScheduleView"
            ? "Do you want to make the call or should I?"
            : "When's the best time to call?"}
        </h1>
      </div>
      {viewType == "beforeCallScheduleView" ? (
        <div className="flex items-center w-full gap-2 mx-4 relative">
          <div onClick={handleCallClick} className={buttonBar}>
            <img
              src={callUs}
              alt="call us"
              width={70}
              height={70}
              className="absolute top-4 p-1  border-[1px]  border-gray-100 rounded-lg bg-white  "
            />
            <p className="text-sm font-semibold py-2 !pt-[25px] dark:text-white">
              {" "}
              {userData?.options?.[0]?.title || userData?.leftBoxText}
            </p>
            <span className="text-sm text-[black] cursor-default py-4 dark:text-white">
              Call and speak with a service agent
            </span>
            <button className="text-xs font-semibold my-4 py-2 flex items-center justify-center rounded-full uppercase  text-black bg bg-gray-200 w-[155px] h-30px gap-1 cursor-pointer">
              <MdAccessTime size={15} />

              {"20 M"}
            </button>
          </div>
          <div onClick={handleRequestCall} className={buttonBar}>
            <img
              src={requestCall}
              alt="call us"
              width={70}
              height={70}
              className="absolute top-4 p-1  border-[1px]  border-gray-100 rounded-lg bg-white"
            />
            <p className="text-sm font-semibold py-2 !pt-[25px] dark:text-white">
              {" "}
              {userData?.options?.[1]?.title || userData?.rightBoxText}
            </p>
            <span className="text-sm text-[black] cursor-default py-4 dark:text-white">
              iAllo will wait on hold and connect you.
            </span>
            <button className="text-xs px-4 flex my-4 py-2 items-center cursor-pointer justify-center rounded-full uppercase font-semibold text-white bg bg-gradient-to-r from-[#2DC9EB] to-[#14D2B8] w-[155px] h-30px">
              RECOMMENDED
            </button>
          </div>
        </div>
      ) : (
        <div className="px-4 mt-5 flex justify-between">
          <button
            className={`${btnClassName} bg-ipurple hover:bg-[#5A70F9] text-white dark:border-darkModeBorder`}
            onClick={() => handleCallNow()}
          >
            NOW
          </button>
          <button
            className={`${btnClassName} bg-ipurple hover:bg-[#5A70F9] text-white dark:border-darkModeBorder`}
            onClick={handleScheduleCallClick}
          >
            SCHEDULE A CALL
          </button>
        </div>
      )}
      <div className="flex justify-center py-3 ">
        <button
          className="text-xs px-4  py-2 mt-2 flex items-center justify-center border-[1px] dark:text-white  border-gray-100 dark:border-darkBorder rounded-full uppercase font-semibold text-black w-[155px] h-30px"
          onClick={() => {
            handleCancelBtn();
          }}
        >
          CANCEL
        </button>
      </div>
    </div>
  );
}
