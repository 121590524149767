import { useState, useEffect, useRef, useCallback } from "react";
import { IoChevronDown, IoLogOut, IoPower } from "react-icons/io5";
import { IoIosPower } from "react-icons/io";
import { LuCheck } from "react-icons/lu";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import {
  getCompanyId,
  getUserName,
  useAuth,
  userDetails,
} from "../../routes/ProtectedRoutes";
import { useNavigate } from "react-router-dom";
import Avatar from "../Avatar";
import { clearSipParams } from "../../utils/DeviceDetails/SipDetails";
import { PRO_USER, VITE_API_PAGE_LIMIT } from "../../utils/constants";
import { getAccountType } from "../../routes/ProtectedRoutes";
import { sendMixpanelEvent } from "../../services/mixpanelService";
import { CompaniesService } from "../../services/companies/CompaniesService";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import User from "../../assets/icons/User.svg";
import { SettingsIcon } from "../icons/SettingsIcon";
import { NavLink } from "react-router-dom";

import { useSearch } from "../../context/SearchContext";
export default function Accordian() {
  const [isOpen, setIsOpen] = useState(false);
  const { setSearchTerm, setSelectedLocation, setSearchViewToggle } =
    useSearch();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const userDetailsProfile = userDetails();
  const userName = getUserName();
  const accountType = getAccountType();
  const {
    companyPhoneNumberListApi,
    getDefaultUsercompanyPhoneApi,
    defaultUsercompanyPhoneEditApi,
  } = CompaniesService();
  const [companyPhones, setCompanyPhones] = useState([]);
  const [defaultUserCompanyPhone, setDefaultUserCompanyPhone] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = VITE_API_PAGE_LIMIT;
  const companyId = getCompanyId();

  const toggleDropdown = async () => {
    setIsOpen(!isOpen);
    await Promise.all([fetchCompanyPhones(), fetchUserCompanyPhone()]);
  };

  const fetchCompanyPhones = async () => {
    const queryParams = { page: page, limit: limit, verified: true };

    try {
      if (companyId) {
        const response = await companyPhoneNumberListApi(
          companyId,
          queryParams,
        );
        setCompanyPhones(response);
        setPage(page);
        setHasMore(true);
      }
      return true; // Return a resolved promise
    } catch (error) {
      console.error("Error fetching company phone numbers:", error);
      return false; // Return a rejected promise
    }
  };

  // Fetch remaining company phones if exists - Used for Pagination
  const fetchMoreCompanyPhones = useCallback(async () => {
    if (!hasMore) return;
    try {
      const nextPage = page + 1;

      const queryParams = {
        page: nextPage,
        limit: limit,
        verified: true,
      };

      const response = await companyPhoneNumberListApi(companyId, queryParams);
      if (response.length > 0) {
        // Append new data to current data
        setCompanyPhones((prevPhonesData) => [...prevPhonesData, ...response]);
        setPage(nextPage);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more company phones:", error);
    }
  }, [page, companyId, hasMore]);

  const fetchUserCompanyPhone = async () => {
    try {
      const response = await getDefaultUsercompanyPhoneApi();
      setDefaultUserCompanyPhone(response);
      return true; // Return a resolved promise
    } catch (error) {
      console.error("Error fetching default user company phone number:", error);
      return false; // Return a rejected promise
    }
  };
  const handlePhoneUpdateClick = async (phone) => {
    if (defaultUserCompanyPhone?.id === phone.id) {
      toast.error(
        "This phone number is already set as default. Please choose a different one!",
      );
      return;
    }

    const previousDefaultPhone = defaultUserCompanyPhone;
    setDefaultUserCompanyPhone(phone);

    try {
      await defaultUsercompanyPhoneEditApi(phone.id);
      toast.success("Default Phone number updated successfully!");
      // Update companyPhones state directly to reflect the change immediately
      const updatedCompanyPhones = companyPhones.map((p) =>
        p.id === phone.id ? { ...p, isDefault: true } : p,
      );
      setCompanyPhones(updatedCompanyPhones);
      await fetchUserCompanyPhone();
    } catch (error) {
      setDefaultUserCompanyPhone(previousDefaultPhone);
      toast.error("Error updating Default Phone number!");
      console.error("Error updating Default Phone number!", error);
    }
  };
  if (accountType === PRO_USER) {
    useEffect(() => {
      fetchCompanyPhones();
      fetchUserCompanyPhone();
    }, [companyId]);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const { LogoutApi } = AuthPersonalPhone((state) => state);

  const handleLogout = async () => {
    await LogoutApi()
      .then(async () => {
        const sipCallData = clearSipParams();
        await window.postMessage(sipCallData, "*");
        localStorage.removeItem("theme");
        await navigate("/");
        sendMixpanelEvent("Logged Out Successfully");
      })
      .catch(() => {
        navigate("/");
      });
  };

  // Sort companyPhones to ensure the default phone number is at the top
  const sortedCompanyPhones = companyPhones.sort((a, b) => {
    if (
      defaultUserCompanyPhone?.country_code === a.country_code &&
      defaultUserCompanyPhone?.phone_number === a.phone_number
    ) {
      return -1;
    }
    if (
      defaultUserCompanyPhone?.country_code === b.country_code &&
      defaultUserCompanyPhone?.phone_number === b.phone_number
    ) {
      return 1;
    }
    return 0;
  });

  return (
    <div
      ref={dropdownRef}
      className="flex items-center justify-center pl-1 relative"
    >
      <div className="flex items-center text-xs transition duration-150 ease-in-out cursor-pointer">
        <div
          className="flex flex-col items-start gap-1 ml-2 whitespace-nowrap text-sm font-sans font-bold"
          onClick={() => navigate("/profile")}
        >
          <span>{userName}</span>
          <span className="text-xs font-normal">
            {defaultUserCompanyPhone?.company_phone_number?.department || ""}
          </span>
        </div>

        <div className="flex items-center p-[15pt] ">
          <div
            onClick={toggleDropdown} // Use toggleDropdown here
            className="cursor-pointer group"
          >
            <IoChevronDown
              size={20}
              className={`transition-transform ${isOpen ? "rotate-180 text-blue-500" : "text-black dark:text-white"} mb-1 -mr-3`}
            />
          </div>
        </div>
      </div>

      {/* Dropdown content */}
      {isOpen && (
        <div className=" absolute right-2 z-50 mt-5 border-gray-100 border rounded-md dark:border-darkModeBorder flex flex-col w-[320px] top-[50px] bg-white rounded-b-md shadow-lg outline-none dark:bg-darkModeMain font-montserrat">
          <div className="flex flex-col mr-1">
            {accountType !== PRO_USER ? (
              <>
                <div className="flex pl-2 border-b w-[318px] border-gray-100 dark:border-darkModeBorder items-center py-1">
                  <a
                    href="#"
                    className="p-3 text-sm font-semibold text-darkModeBG dark:text-neutral-200"
                  >
                    Switch Profile
                  </a>
                </div>
                <NavLink
                  to="/profile"
                  onClick={() => {
                    setSearchTerm("");
                    setSelectedLocation("");
                  }}
                >
                  <div className="hover:bg-lightBg dark:hover:bg-darkModeBG flex pl-4 border-b w-[318px] border-gray-100 gap-2 items-center dark:border-darkModeBorder h-[70px]">
                    <div>
                      <Avatar
                        pathSrc={userDetailsProfile?.upload?.file_path || User}
                      />
                    </div>
                    <NavLink
                      to={`/profile`}
                      className="text-[14px] -ml-0 mb-1 font-semibold text-darkModeBG dark:text-neutral-200"
                    >
                      {userName}
                    </NavLink>
                    <p className="text-[13px] text-gray-400">Personal</p>
                    <div className="right-6 text-ipurple">
                      <LuCheck size={20} />
                    </div>
                  </div>
                </NavLink>

                <button
                  onClick={handleLogout}
                  className="flex gap-2  w-[318px] py-4 text-[13px] rounded-b-md hover:bg-lightBg dark:hover:bg-darkModeBG text-darkModeBG dark:text-neutral-200"
                >
                  <IoIosPower size={20} className="ml-6" />
                  <p>Logout</p>
                </button>
              </>
            ) : (
              <div className="right-0 w-[320px] rounded-lg dark:border-darkModeBorder ">
                {companyPhones.length > 0 ? (
                  <>
                    <div className=" z-100 flex pl-4 items-center py-3 w-[320px]">
                      <p className="text-xs font-normal">Department</p>
                    </div>

                    <div
                      id="scrollableDiv"
                      className={`relative ${
                        sortedCompanyPhones.length < 3
                          ? "h-auto"
                          : "h-[calc(290px-125px)]"
                      } z-10 overflow-y-auto overflow-x-hidden`}
                    >
                      <InfiniteScroll
                        dataLength={sortedCompanyPhones.length}
                        next={fetchMoreCompanyPhones}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        scrollThreshold={1.0}
                        scrollableTarget="scrollableDiv"
                        style={{ display: "table", width: "100%" }}
                        className="mr-2"
                      >
                        {sortedCompanyPhones.map((phone) => (
                          <div
                            key={phone.id}
                            className={`hover:bg-lightBg dark:hover:bg-darkModeBG flex space-x-1 border-gray-100 dark:border-darkModeBorder items-center w-[290px] py-3 h-[40px] rounded border mx-4 my-3 ${
                              defaultUserCompanyPhone?.country_code ===
                                phone?.country_code &&
                              defaultUserCompanyPhone?.phone_number ===
                                phone?.phone_number
                                ? "border-ipurple dark:border-ipurple"
                                : "border-b"
                            }`}
                            onClick={() => {
                              const confirmUpdate = window.confirm(
                                "Are you sure you want to update the default phone number?",
                              );
                              if (confirmUpdate) {
                                handlePhoneUpdateClick(phone);
                              }
                            }}
                          >
                            <span className="inline-block whitespace-nowrap rounded-[0.27rem] px-[0.5rem] py-[0.5rem] text-left align-baseline text-[13px] font-bold leading-none w-full">
                              {phone?.department || ""}
                            </span>
                            <p className="font-normal text-[#6C7B8A] h-[20px] text-left align-baseline text-[13px] w-full">
                              {phone?.country_code +
                                " " +
                                phone?.phone_number.replace(
                                  /^(\d{3})(\d{3})(\d+)/,
                                  "$1$2$3",
                                ) || ""}
                            </p>
                            {defaultUserCompanyPhone?.country_code ===
                              phone?.country_code &&
                              defaultUserCompanyPhone?.phone_number ===
                                phone?.phone_number && (
                                <div className="absolute right-6 text-ipurple">
                                  <LuCheck size={20} />
                                </div>
                              )}
                          </div>
                        ))}
                      </InfiniteScroll>
                    </div>
                  </>
                ) : (
                  <div className="hover:bg-lightBg dark:hover:bg-darkModeBG pl-1 flex flex-col space-x-1 items-left w-[318px] py-1 h-full rounded   my-3 text-[13px] font-normal tracking-normal text-left leading-[22px]">
                    <span className="pl-1">
                      No company phones have been added yet.
                    </span>
                    <a
                      onClick={() => navigate("/team/roles&Phones")}
                      className="font-semibold underline cursor-pointer"
                    >
                      Add Phone number
                    </a>
                  </div>
                )}
                <div className=" z-100 flex pl-4 border-b border-gray-100 dark:border-darkModeBorder items-center py-1 w-[318px]">
                  <p className="text-xs font-normal">Account</p>
                </div>
                <div
                  className="hover:text-ipurple cursor-pointer dark:hover:bg-darkModeBG w-[318px] text-sm z-100 h-[40px] flex border-b border-gray-100 dark:border-darkModeBorder items-center px-2 py-2 font-semibold group"
                  onClick={() => navigate("/settings")}
                >
                  <SettingsIcon className="h-[20px] text-black dark:text-white group-hover:text-ipurple" />
                  <span className="h-[20px] ml-2  group-hover:text-ipurple">
                    Settings
                  </span>
                </div>
                <div
                  className="hover:!text-ipurple dark:hover:bg-darkModeBG cursor-pointer w-[318px] z-10 h-[40px] flex px-2 border-gray-100 dark:border-darkModeBorder items-center py-2 font-semibold  rounded-b-md  overflow-hidden"
                  onClick={handleLogout}
                >
                  <IoIosPower className="h-[20px] mx-2" />
                  <span className="h-[20px] ml-2 text-sm">Log out</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
