import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PasswordInput from "../../Inputs/PasswordInput";
import { FaChevronRight } from "react-icons/fa6";
import { AuthPersonalPhone } from "../../../services/auth/personal/AuthPersonal";
import { SettingService } from "../../../services/setting/SettingService";
import { useFormik } from "formik";
import validatePassword from "../../../utils/validate/validatePassword";
import { useNavigate } from "react-router-dom";
import { logOut, userDetails } from "../../../routes/ProtectedRoutes";
import toast from "react-hot-toast";
import { sendMixpanelEvent } from "../../../services/mixpanelService";

export default function SecurityView({ onShowPinCode }) {
  const [isPinToggled, setIsPinToggled] = useState(false);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const userData = userDetails();
  const { ProfileDetails, getProfileDetails, LogoutApi } = AuthPersonalPhone(
    (state) => state
  );
  const initialState = {
    confirm_password: "",
    new_password: "",
  };
  const { pinCodeStatusApi, changePasswordApi } = SettingService(
    (state) => state
  );
  const { setPasswordApi } = AuthPersonalPhone();
  const handlePinToggle = () => {
    setIsPinToggled(!isPinToggled);
  };

  useEffect(() => {
    if (ProfileDetails) {
      setIsPinToggled(ProfileDetails.is_security_pin_code);
    }
  }, [ProfileDetails]);

  const updatePin = async () => {
    await pinCodeStatusApi({ is_security_pin_code: isPinToggled });
    getProfileDetails();
  };

  useEffect(() => {
    if (toggle) {
      updatePin();
    }
  }, [toggle]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    sendMixpanelEvent("Change Password Initiated");
  };

  const handleUpdatePassword = () => {
    if (
      validatePassword(formik.values.confirm_password) &&
      validatePassword(formik.values.new_password)
    ) {
      changePasswordApi({
        current_password: formik.values.confirm_password,
        new_password: formik.values.new_password,
      })
        .then((data) => {
          if (data.success == true) {
            toast.success(
              "Password is updated successfully. Please sign in again."
            );
            sendMixpanelEvent("Password Changed Successfully");
            logOut(LogoutApi, navigate);
          }
        })
        .catch((error) => console.log(error));
    }
  };
  const handleSetNewPassword = () => {
    if (
      validatePassword(formik.values.confirm_password) &&
      validatePassword(formik.values.new_password) &&
      formik.values.new_password === formik.values.confirm_password
    ) {
      setPasswordApi({
        password: formik.values.confirm_password,
      })
        .then((data) => {
          if (data.success == true) {
            toast.success(
              "Password is updated successfully. Please sign in again."
            );
            logOut(LogoutApi, navigate);
          }
        })
        .catch((error) => console.log(error));
    } else {
      toast.error("Please enter the valid password.");
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: handleUpdatePassword,
  });

  const isSaveButtonActive = () => {
    return (
      formik.values.confirm_password &&
      formik.values.new_password &&
      validatePassword(formik.values.confirm_password) &&
      validatePassword(formik.values.new_password)
    );
  };

  return (
    <div className="flex flex-col w-full text-sm scrollable-container h-full overflow-y-auto">
      <h1 className="p-6 font-bold text-[16px]">Password Protection</h1>
      <p className="px-6 pb-3">
        Personal password is set for your iAllo account. You need to enter it
        when authorizing on new device.
      </p>
      <div className="flex flex-wrap items-center w-full gap-4 px-6 pt-6">
        {userData?.password_added && (
          <div className="flex flex-col space-y-2">
            <p>Old Password</p>

            <div>
              <PasswordInput
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col space-y-2">
          {userData?.password_added ? <p>New Password</p> : <p>Password</p>}
          <PasswordInput
            name="new_password"
            value={formik.values.new_password}
            onChange={handleChange}
          />
        </div>
        {!userData?.password_added && (
          <div className="flex flex-col space-y-2">
            <p>Confirm Password</p>
            <PasswordInput
              name="confirm_password"
              value={formik.values.confirm_password}
              onChange={handleChange}
            />
          </div>
        )}
        {!userData?.password_added ? (
          <button
            className={
              isSaveButtonActive()
                ? "w-[240px] mt-6 h-[45px] bg-ipurple uppercase items-center justify-center text-white rounded-full hover:font-bold flex text-center"
                : "w-[240px] mt-6 h-[45px] bg-gray-300 uppercase items-center justify-center text-white rounded-full hover:font-bold flex text-center"
            }
            onClick={handleSetNewPassword}
            disabled={!isSaveButtonActive()}
          >
            Set password
          </button>
        ) : (
          <button
            className={
              isSaveButtonActive()
                ? "w-[240px] mt-6 h-[45px] bg-ipurple uppercase items-center justify-center text-white rounded-full hover:font-bold flex text-center"
                : "w-[240px] mt-6 h-[45px] bg-gray-300 uppercase items-center justify-center text-white rounded-full hover:font-bold flex text-center"
            }
            onClick={handleUpdatePassword}
            disabled={!isSaveButtonActive()}
          >
            Save password
          </button>
        )}
      </div>
      <h2 className="font-semibold text-[16px] pt-6 px-6 pb-3">Pin Code</h2>
      <p className="px-6">
        PIN code is used for authorization and security purposes on the current
        device.
      </p>
      <div className="flex gap-4 items-center pt-8 pb-3 w-full  border-b border-gray-100-[1px] border-gray-100  dark:border-darkModeBorder">
        <h2 className="font-semibold px-6 text-[16px] ">Use PIN code</h2>
        <div
          className={` my-2 relative w-[45px] h-[25px] rounded-full cursor-pointer dark:fill-white ${
            isPinToggled ? "bg-[#4c5fef3b] dark:bg-secondary-900 " : "dark:bg-gray-400"
          }`}
          onClick={() => {
            setToggle(!toggle);

            if (isPinToggled) {
              sendMixpanelEvent("Pincode Turned OFF");
            } else {
              sendMixpanelEvent("Pincode Turned On");
            }

            handlePinToggle();
          }}
        >
          <div
            className={`h-[21px] w-[21px] rounded-full dark:text-white absolute top-0.5 transition-transform duration-300 ease-in-out ${
              isPinToggled
                ? "translate-x-[21px] bg-ipurple"
                : "bg-white dark:bg-darkModeMain translate-x-0.5"
            }`}
          ></div>
        </div>
      </div>

      <div className="flex justify-between w-full py-[19.5px]  items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
        <h2 className="font-semibold text-[16px] px-6">Change PIN Code</h2>
        <FaChevronRight
          onClick={onShowPinCode}
          size={20}
          className="mr-6 hover:cursor-pointer"
        />
      </div>
    </div>
  );
}

// Add PropTypes validation
SecurityView.propTypes = {
  onShowPinCode: PropTypes.func.isRequired,
};