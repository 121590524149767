import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { PiPlusCircleFill, PiArrowRight } from "react-icons/pi";
import PeopleCard from "../PeopleCard";
import ActivityCard from "../ActivityCard";
import { TicketsDetails } from "../../../services/tickets/TicketDetails";
import { DateTimeFormat } from "../../../utils/DateTimeFormat";
import CloseTicketPopup from "../CloseTicketPopup";
import PeoplePopup from "../PeoplePopup";
import { CallDetailsService } from "../../../services/calls/CallDetailsService";
import toast from "react-hot-toast";
import { CalendarIcon } from "../../icons/Icons";
import { TicketIcon } from "../../icons/TicketIcon";

function StatusView(props) {
  let { getDataApi } = props || {};
  const navigate = useNavigate();

  const [ticketDetails, setTicketDetails] = useState({});
  const { listSingleTicketDetails } = TicketsDetails((state) => state);
  let { status, id } = listSingleTicketDetails?.data || {};

  // let { data } = listSingleTicketDetails || {};
  const [data, setData] = useState(listSingleTicketDetails);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [showTagInput, setShowTagInput] = useState(false);
  const [showPeoplePopup, setShowPeoplePopup] = useState(false);
  const [isTicketClosed, setIsTicketClosed] = useState(false);
  const [isCloseTicketPopupVisible, setIsCloseTicketPopupVisible] =
    useState(false);
  let { unique_id, updated_at, created_at, description, chat_id, business_id } =
    data?.data || {};

  const handleShowOriginalMessage = () => {
    navigate("/chat", {
      state: {
        // chat_id: chat_id || id,
        businessChatId: chat_id || id,
        hitChatApi: true,
        business_id: business_id,
        // message_text: "To request a call, tell me your issue",
      },
    });
  };
  const togglePeoplePopup = () => setShowPeoplePopup(!showPeoplePopup);
  const { ticketUpdateCallsDetailsApi } = CallDetailsService((state) => state);
  const toggleCloseTicketPopup = () => {
    setIsCloseTicketPopupVisible(!isCloseTicketPopupVisible);
  };

  useEffect(() => {
    setData(listSingleTicketDetails);
    setTags(data?.data?.ticket_tags || []);
  }, [listSingleTicketDetails, data?.data?.ticket_tags]);

  const handleAddTag = () => {
    if (newTag.trim()) {
      const newTagObj = { id: Date.now(), tag: { name: newTag.trim() } };
      setTags([...tags, newTagObj]);
      setNewTag("");
      setShowTagInput(false);
    }
  };

  const handleCancelNewTag = () => {
    setNewTag("");
    setShowTagInput(false);
  };

  const handleDeleteTag = (id) => {
    setTags(tags.filter((tag) => tag.id !== id));
  };

  const updateStatusToClosed = async ({ ticketId, ticketStatus }) => {
    await ticketUpdateCallsDetailsApi({
      id: ticketId,
      data: { status: ticketStatus == "closed" ? "open" : "closed" },
    }).then((response) => {
      if (response?.message == "SUCCESS UPDATE") {
        toast.success(
          `Ticket ${
            ticketStatus == "closed" ? "re-open" : "closed"
          } successfully`,
        );
        setIsCloseTicketPopupVisible(!isCloseTicketPopupVisible);
      }
    });
    await getDataApi();
  };

  useEffect(() => {
    setTicketDetails(data?.data);
  }, [data?.data]);

  return (
    <div>
      {/* {loadingTicketDetails == true && <Loader />} */}

      {isCloseTicketPopupVisible && (
        <CloseTicketPopup
          onClose={toggleCloseTicketPopup}
          isTicketClosed={isTicketClosed}
          handleApiCall={() => {
            let handleApiCall = async () => {
              await updateStatusToClosed({
                ticketId: id,
                ticketStatus: status,
              });
            };
            handleApiCall();
          }}
        />
      )}

      <div className="flex flex-col w-full h-full gap-4 text-sm ">
        <div className="flex flex-col space-y-4 ">
          <div className="w-full relative   bg-white dark:bg-darkModeMain flex flex-col rounded-lg  border-[1px]  border-gray-100  dark:border-darkModeBorder ">
            <div className="w-full flex justify-between  px-4 py-6 h-[75px]  border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
              <div className="flex items-center gap-3">
                <CalendarIcon className="text-black dark:text-white w-[25px]" />
                <p>Ticket Created</p>

                {/* <p className="hidden font-semibold ">{"#" + (unique_id || "")}</p> */}

                <p className="font-semibold font text-sm">
                  {" "}
                  {created_at ? DateTimeFormat(created_at) : ""}
                </p>
              </div>

              <div className="flex items-center gap-4">
                <TicketIcon className="text-black dark:text-white w-[23px]" />
                <p className="-ml-2 text-sm">Ticket status</p>
                <div
                  className={`font-[600] gap-1 text-[10px] uppercase w-[80px] rounded-full dark:bg-darkModeBorder min-w-[80px] h-[22px] flex items-center justify-center ${
                    status === "closed"
                      ? "bg-gray-100 text-gray-400"
                      : "bg-[#4c5fef23] text-ipurple"
                  }`}
                >
                  <div
                    className={`w-[6px] h-[6px] rounded-full ${
                      status === "open"
                        ? "bg-ipurple"
                        : "bg-gray-400 dark:text-gray-400"
                    }`}
                  ></div>

                  <p>{status}</p>
                </div>

                {/* <p className="ml-1.5 flex justify-center"> */}
                {/*  */}
                {/* </p> */}
              </div>
              {/* {status == "closed" ? (
                  <button
                    onClick={() => {
                      // toggleCloseTicketPopup();
                      // setIsTicketClosed(true);
                      let handleApiCall = async () => {
                        await updateStatusToClosed({
                          ticketId: id,
                          ticketStatus: "closed",
                        });
                      };
                      handleApiCall();
                    }}
                    className="rounded-full text-sm border-2  py-3 px-8 text-[13px] hover:bg-[#383348] hover:text-white uppercase border-b border-gray-100lack dark:border-white font-semibold flex justify-center items-center"
                  >
                    Re-Open ticket
                  </button>
                ) : (
                  <button
                    onClick={toggleCloseTicketPopup}
                    className="rounded-full text-sm h-[40px] w-[150px] border-2 hover:bg-[#383348] hover:text-white text-[13px] uppercase border-black border-gray-100  dark:border-white font-semibold flex justify-center items-center"
                  >
                    Close ticket
                  </button>
                )} */}
            </div>
          </div>
          {description && (
            <div className="w-full  px-4  py-1 max-h-[8rem]  min-h-[0.5rem] overflow-none  dark:border-darkModeBorder">
              <div className="flex flex-col overflow-y-scroll-auto max-h-[205px]">
                <div className="w-full  max-h-[100px] p-1 !py-3.5 dark:border-darkModeBorder flex flex-col justify-center ">
                  <p className="truncate text-sm">
                    {description !== "null" && description !== ""
                      ? description
                      : ""}
                  </p>
                  {chat_id && (
                    <button
                      onClick={handleShowOriginalMessage}
                      className="flex items-center gap-2 font-semibold mx-0 my-2 text-sm pt-1"
                    >
                      <p className="">Show original message</p>
                      <PiArrowRight size={18} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center w-full px-6 min-h-[75px]  border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex-wrap ">
            <button
              onClick={() => setShowTagInput(!showTagInput)}
              className="my-2 mr-2 text-ipurple"
            >
              <PiPlusCircleFill size={30} className="min-w-[30px]" />
            </button>

            {tags.length === 0 && (
              <span className="text-ipurple" style={{ alignSelf: "center" }}>
                Add tag
              </span>
            )}

            {tags.map((data) => (
              <div key={data.id} className="flex px-2 py-5 ">
                <button className="border-[1px]  border-gray-100  h-[35px] max-w-[180px] rounded-lg bg-[#4c5fef23] border-ipurple p-2 text-ipurple flex items-center justify-center gap-1">
                  <p className="max-w-[170px] overflow-hidden truncate">
                    {data.tag.name}
                  </p>
                  <IoCloseOutline
                    size={16}
                    className="min-w-[16px]"
                    onClick={() => handleDeleteTag(data.id)}
                  />
                </button>
              </div>
            ))}
            {showTagInput && (
              <div className="flex items-center w-full gap-3 pb-6">
                <input
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  type="text"
                  placeholder="Enter new tag"
                  className="w-full font-bold rounded-md border-ipurple placeholder:text-sm bg-inherit focus:ring-ipurple "
                />
                <button
                  onClick={handleCancelNewTag}
                  className="px-4 py-2 text-xs font-semibold uppercase border-2 border-b border-gray-100lack rounded-full dark:border-white"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddTag}
                  className="px-6 py-2 text-xs font-semibold text-white uppercase border-2 rounded-full border-ipurple bg-ipurple"
                >
                  Add
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex-col hidden w-1/2 space-y-4 lg:w-1/3">
        <div className="w-full mr-4 h-[340px] bg-white overflow-y-scroll rounded-lg flex flex-col dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder">
          <div className="flex items-center h-[60px] justify-between p-6 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ">
            <div className="flex gap-1 font-semibold uppercase ">
              <p>People</p>
              <p>(4)</p>
            </div>
            <button
              onClick={togglePeoplePopup}
              className="font-semibold text-[13px] items-center flex gap-1"
            >
              <p>Show all</p>
              <PiArrowRight size={16} />
            </button>

            {showPeoplePopup && <PeoplePopup onClose={togglePeoplePopup} />}
          </div>
          <PeopleCard />
        </div>
      </div>

      <div className="w-full  h-full overflow-y-auto bg-white rounded-lg flex flex-col dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder">
        <div className="h-[60px] p-4  w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder uppercase font-semibold flex  items-center justify-between">
          <p className="text-sm">Activity</p>
          {/* <button className="font-semibold text-[13px] items-center flex gap-1">
              <p>Show all</p>
              <PiArrowRight size={16} />
            </button> */}
        </div>
        <ActivityCard
          ticketActivitiesdata={data?.data?.log_activities}
          ticketId={id}
        />
      </div>
    </div>
  );
}
export default StatusView;
