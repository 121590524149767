import { VITE_SIP_ADDRESS, VITE_SIP_DOMAIN } from "../constants";

export const SipDetails = () => {
  let sipDetailsData = {
    sipUsernameRef: localStorage.getItem("uuid") || "",
    sipDomainRef: VITE_SIP_DOMAIN,
    sipPasswordRef: localStorage.getItem("sipDetails") || "",
    sipDisplayNameRef: JSON.parse(localStorage.getItem("userDetails"))
      ?.first_name,
    sipPhoneNumber: JSON.parse(localStorage.getItem("userDetails"))
      ?.phone_number,
    sipServerAddress: VITE_SIP_ADDRESS,
    sipAuthToken: localStorage.getItem("authLogin") || "",
  };

  return sipDetailsData;
};

export const sipCallParams = (number, dataParams = {}) => {
  if (number && number.charAt(0) !== "+") {
    number = `+${number}`;
  }
  let sipDetail = {
    type: "OPEN_EXTENSION",
    data: {
      sipDomain: VITE_SIP_DOMAIN,
      sipServerAddress: VITE_SIP_ADDRESS,
      sipUsername: localStorage.getItem("uuid") || "",
      sipPassword: localStorage.getItem("sipDetails") || "",
      sipDisplayName: JSON.parse(localStorage.getItem("userDetails"))
        ?.first_name,
      deviceId: localStorage.getItem("uuid") || "",
      authToken: localStorage.getItem("authLogin") || "",
      callingPhoneNumber: number,
      userImageUrl: localStorage.getItem("userDetails")
        ? JSON.parse(localStorage.getItem("userDetails"))?.upload?.file_path
        : "",
      sipBusinessId: dataParams.business_id,
      sipTicketId: dataParams.ticket_id,
      sipChatId: dataParams.chat_id,
    },
  };
  // Send message to the extension

  return sipDetail;
};

export const clearSipParams = () => {
  let sipDetail = {
    type: "OPEN_EXTENSION",
    data: {
      sipDomain: "",
      sipServerAddress: "",
      sipUsername: "",
      sipPassword: "",
      sipDisplayName: "",
      deviceId: "",
      authToken: "",
      callingPhoneNumber: "",
      userImageUrl: "",
    },
  };
  // Send message to the extension

  return sipDetail;
};

// Check if extension is installed, login on extension
export function extensionLogin() {
  const extensionStatus = JSON.parse(localStorage.getItem("isExtension"));

  if (extensionStatus == true) {
    const sipCallData = sipCallParams(null);
    window.postMessage({ ...sipCallData, extensionTrigger: true }, "*");
  }
}
