import { useNavigate } from "react-router-dom";
import { EditIcon, Trash, SpamIcon } from "../icons/Icons";
import PropTypes from "prop-types";

BusProfileDropdown.propTypes = {
  onDeleteClick: PropTypes.func,
  onReportClick: PropTypes.func,
  businessid: PropTypes.any,
  verified: PropTypes.any,
};

export default function BusProfileDropdown(props) {
  const { onDeleteClick, onReportClick, businessid, verified } = props;

  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/edit-business/${businessid}`);
  };

  return (

    <div className="flex flex-col text-[14px] border  dark:border-darkModeBorder justify-center items-center w-[220px] h-auto bg-white shadow-lg rounded-lg  dark:bg-darkModeMain -mt-5 ">
      {!verified && (
        <button
          onClick={handleEditClick}
          className="flex items-center w-full gap-2 p-2 hover:bg-gray-100 dark:hover:bg-darkModeBorder rounded-t-lg"
        >
          <EditIcon className=" text-black hover:text-ipurple dark:text-white h-[23px] w-[21px] mt-[5px] mr-[4px]" />
          <p className="text-black-800 dark:text-white">Edit business</p>
        </button>
      )}
      {verified && (
    <button
    onClick={onReportClick}
    className="flex items-center w-full gap-2 p-2 dark:hover:font-semibold">

    <SpamIcon className="w-[25px] dark:text-white svg-icon" />
    <p>Report / Request update</p>
  </button>
    )}
      {!verified && (
        <button
          onClick={onDeleteClick}
          className="flex items-center w-full gap-3 p-2  hover:bg-gray-100 dark:hover:bg-darkModeBorder rounded-b-lg"
        >
          <Trash className="w-[25px] h-[25px] -mr-1 dark:text-gray-300 text-black " />
          <p className="text-gray-700 dark:text-white">Delete Business</p>
        </button>
      )}
    </div>
  );
}
