import { useEffect, useMemo, useState } from "react";
import { assignRandomColor } from "../../utils/common";

const DirectoryImageInitials = (props) => {
  let { imageUrl, alt, name, className, boxColor, bgColourWhite, boxColorImg, boxBorder } = props || {};

  const [imageExists, setImageExists] = useState(null);

  const profileInitials = (name) => {
    if (name) {
      const initials = name?.split(" ");
      let initialsResult = initials?.map((word) => word[0])?.slice(0, 2);
      initialsResult = initialsResult.map((letter) => letter?.toUpperCase());
      return initialsResult.join("");
    }
    return "";
  };

  useEffect(() => {
    const checkImage = () => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        setImageExists(true);
      };

      img.onerror = () => {
        setImageExists(false);
      };
    };

    imageUrl ? checkImage() : setImageExists(false);
  }, [imageUrl]);
  
  const imageClasses = boxBorder
    ? 'h-[20px] w-[25px]'
    : 'h-[60px] w-[60px]';

  const randomColor = useMemo(() => {
    return assignRandomColor();
  }, []);
  return (
    <div
    className={`w-[65px] min-w-[30px] h-[65px] min-h-[30px] font-bold flex items-center justify-center border-white dark:border-darkModeBorder overflow-hidden border-1 rounded-xl ${className} cursor-pointer`}
    style={
        imageExists === false
          ? {
            backgroundColor: boxColor || randomColor, // Default to black if boxColor not provided
            color: "#FFFFFF", // Text color set to white
          }
          : {
            border: boxBorder
          }
      }
    >
      {imageExists ? (
          <img
            src={imageUrl}
            alt={alt}
            className={` ${imageClasses} object-contain `}
          />
      ) : (
        <span>{profileInitials(name)}</span>
      )}
    </div>
  );
};

export default DirectoryImageInitials;
