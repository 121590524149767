import { useState } from "react";
import ReviewsCard from "./ReviewsCard";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import ReviewPopup from "../ReviewPopup";
import { IoStar } from "react-icons/io5";
import IaolloScore from "./IaolloScore";
import Dark from "../../assets/icons/icon.dark.svg";

export default function ReviewsView(props) {
  const { reviewData, fetchBusinessDetails } = DirectoryDetails(
    (state) => state,
  );

  let { average_review_rating } = reviewData?.stats?.reviews || {};

  const [showReviewPopup, setShowReviewPopup] = useState(false);

  const handleAddReviewClick = () => {
    setShowReviewPopup(true);
  };

  const handleClosePopup = () => {
    setShowReviewPopup(false);
  };

  const starIcons = Array.from({ length: 5 }, (v, i) => {
    const isFilled = i < Math.floor(average_review_rating);
    const isHalfFilled =
      i === Math.floor(average_review_rating) &&
      average_review_rating % 1 !== 0;

    return isFilled ? (
      <IoStar key={i} size={20} style={{ color: "#F9579A" }} />
    ) : isHalfFilled ? (
      <IoStar
        key={i}
        size={20}
        style={{
          color: "#F9579A",
          clipPath: "polygon(0 0, 50% 0%, 50% 100%, 0 100%)",
        }}
      />
    ) : (
      <IoStar
        key={i}
        size={20}
        className="text-gray-200 dark:text-darkModeBorder"
      />
    );
  });

  if (reviewData?.meta?.total != 0) {
    return (
      <div className="flex flex-col justify-center items-center w-full bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder ">
        {showReviewPopup && (
          <ReviewPopup
            onClose={handleClosePopup}
            fetchBusinessDetails={fetchBusinessDetails}
          />
        )}
        <div className="h-[60px] flex  border-b border-gray-100-[1px] border-gray-100 justify-between dark:border-darkModeBorder w-full  items-center p-4">
          <p className="text-sm font-bold uppercase">Reviews</p>
        </div>
        <div className="w-full flex dark:border-darkModeBorder  items-center justify-center py-6 border-b border-gray-100-[1px] border-gray-100 ">
          <div className="flex flex-col items-center justify-center w-1/4 space-y-3 text-sm text-center">
            <div className="flex items-center justify-center w-full gap-3 ">
              <p className="text-[30px] font-bold">
                {average_review_rating || 0}
              </p>
            </div>
            <p className="">{reviewData?.meta?.total} reviews</p>
            <div className="flex items-center gap-2 text-ipink">
              {starIcons}
            </div>
          </div>
          <div className="h-[150px] mx-4 w-[2px] bg-gray-100 dark:bg-darkModeBG"></div>
          <div className="w-1/2">
            <IaolloScore reviewData={reviewData} />
          </div>
          <div className="h-[150px] w-[2px] bg-gray-100 dark:bg-darkModeBG mx-6"></div>
          <div className="flex items-center justify-center w-1/4">
            <button
              onClick={handleAddReviewClick}
              className="bg-black uppercase text-white hover:bg-inherit hover:font-semibold hover:border-2 hover:border-b border-gray-100lack dark:hover:border-white hover:text-black dark:hover:text-white rounded-full min-h-[45px] w-full mr-6 px-4 py-2"
            >
              Add review
            </button>
          </div>
        </div>
        <div className="max-h-[40rem] w-full overflow-y-auto">
          <ReviewsCard />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col justify-center items-center w-full bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder ">
        {showReviewPopup && (
          <ReviewPopup
            onClose={handleClosePopup}
            fetchBusinessDetails={fetchBusinessDetails}
          />
        )}
        <div className="h-[60px] flex  border-b border-gray-100-[1px] border-gray-100 justify-between dark:border-darkModeBorder w-full  items-center p-4">
          <p className="text-sm font-bold uppercase">Reviews</p>
        </div>
        <div className="flex flex-col items-between justify w-full py-4 px-4">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <p className="text-gray-500 dark:text-gray-300 text-sm">
                There are currently no reviews for this business added.
                <br />
                Be the first to share your experience.
              </p>
            </div>
            <img
              src={ Dark }
              alt="Theme-based icon"
              style={{ width: "64px", height: "64px", opacity:'0.75' }}
              className="ml-4"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              fontSize: "13px",
            }}
          >
            <button
              onClick={handleAddReviewClick}
              className="bg-white uppercase text-black font-semibold border border-gray-100 hover:bg-inherit hover:font-semibold hover:border-2 border-black dark:hover:border-white hover:text-black dark:hover:text-white rounded-full min-h-[45px] mt-4 px-10 py-2 text-[13px]"
            >
              Add review
            </button>
          </div>
        </div>
      </div>
    );
  }
}
