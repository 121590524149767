import { useState, useEffect, useRef } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import CancelCallModal from "./CancelCallModal";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import CloseTicketPopup from "../tickets-tab/CloseTicketPopup";
import RescheduleCallModal from "./RescheduleCallModal";
import { sipCallParams } from "../../utils/DeviceDetails/SipDetails";
import {
  BusinessCalendarIcon,
  BusinessCancelIcon,
  BusinessCloseIcon,
  BusinessCopyIcon,
  BusinessPhoneIcon,
  BusinessViewIcon,
} from "../icons/Icons";
import { checkExtensionStatus } from "../atom/checkExtension";
import { getScheduledTime } from "../../utils/constants";

export default function Dropdown(props) {
  let {
    getApiCallHistory,
    callDetailsData,
    activeFilterName,
    getScheduleCallData,
  } = props || {};

  const scheduledTime = getScheduledTime();
  const [isRecent, setIsRecent] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const checkExtension = useRef(true);
  const [dropdownDirection, setDropdownDirection] = useState("down");
  useEffect(() => {
    if (activeFilterName == "recent") {
      setIsRecent(true);
    } else {
      setIsScheduled(true);
    }
  }, [activeFilterName]);

  let { ticket, ticket_id, id } = callDetailsData || {};
  let callId = id;
  const [isTicketClosed, setIsTicketClosed] = useState(false);
  let ticketStatus = ticket?.status;

  let phoneNumber = callDetailsData?.phone_number;
  const { ticketUpdateCallsDetailsApi, cancelScheduleCallApi } =
    CallDetailsService((state) => state);

  const [showCloseTicketPopup, setShowCloseTicketPopup] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const handleShowRescheduleModal = () => {
    setShowRescheduleModal(true);
  };
  const [showCancelCallModal, setShowCancelCallModal] = useState(false);

  const handleCancelCallClick = () => {
    setShowCancelCallModal(true);
  };

  const handleCloseCancelCallModal = () => {
    setShowCancelCallModal(false);
  };

  const handleCancelCall = () => {
    setShowCancelCallModal(false);
  };

  let navigate = useNavigate();

  const handleViewTicketClick = (ticket_id) => {
    navigate(`/tickets/${ticket_id}`, {
      state: {
        ticketId: ticket_id,
        showTranscriptTab: true,
      },
    });
  };

  const handleCloseRescheduleModal = () => {
    setShowRescheduleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const copyToClipboard = (phoneNumber) => {
    navigator?.clipboard
      .writeText(phoneNumber)
      .then(() => {
        // Display the toast notification on successful copy
        toast.success("Ticket number copied to clipboard!");
      })
      .catch((err) => {
        // You can also display a toast for error if needed

        toast.error("Failed to copy ticket number.");
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const updateStatusToClosed = async ({ ticketId, ticketStatus }) => {
    await ticketUpdateCallsDetailsApi({
      id: ticketId,
      data: { status: ticketStatus == "closed" ? "open" : "closed" },
    }).then((response) => {
      if (response?.message == "SUCCESS UPDATE") {
        toast.success(
          `Ticket ${ticketStatus == "closed" ? "open" : "closed"} successfully`
        );
        setIsOpen(false);
        getApiCallHistory({ ticketId: ticket_id, ticketStatus: ticketStatus });
      }
    });
  };

  useEffect(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 180; // Approximate height of your dropdown

      if (spaceBelow < dropdownHeight) {
        setDropdownDirection("up");
      } else {
        setDropdownDirection("down");
      }
    }
  }, [isOpen]);

  const handleNewTicketCall = async (event) => {
    checkExtension.current = false;
    if (!callDetailsData?.phone_number) return;
    const sipCallData = sipCallParams(callDetailsData?.phone_number);
    window.postMessage(sipCallData, "*");
  };

  const ViewTicketOpt = () => (
    <button
      onClick={() => {
        handleViewTicketClick(ticket_id);
      }}
      className="flex items-center  font-semibold gap-3 p-4 dropdown-item border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder"
    >
      <BusinessViewIcon size={"25px"} />
      <div className="dropdown-link">View ticket</div>
    </button>
  );
  const CancelTicketbtn = () => (
    <button
      onClick={handleCancelCallClick}
      className="flex items-center font-semibold gap-3 p-4 rounded-b-md dropdown-item border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder"
    >
      <BusinessCancelIcon size={"25px"} />
      Cancel
    </button>
  );

  const RescheduleOpt = () => (
    <button
      onClick={handleShowRescheduleModal}
      className="flex items-center gap-3 p-4 font-semibold dropdown-item border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder"
    >
      <BusinessCalendarIcon size={"25px"} />
      <a href="#" className="dropdown-link">
        Reschedule
      </a>
    </button>
  );
  const renderMenuItems = () => {
    if (isScheduled && !isRecent) {
      // Menu for calls that are only scheduled
      return (
        <>
          <button
            disabled={!callDetailsData?.phone_number}
            onClick={() => {
              handleNewTicketCall();
              checkExtensionStatus();
            }}
            className="flex items-center font-semibold rounded-t-md gap-3 p-4 dropdown-item border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder"
          >
            <BusinessPhoneIcon className={"25px"} />
            <a href="#" className="dropdown-link">
              Call
            </a>
          </button>
          <RescheduleOpt />
          <ViewTicketOpt />
          <CancelTicketbtn />
        </>
      );
    } else if (!isScheduled && isRecent) {
      // Menu for calls that are only recent
      return (
        <>
          {showCancelCallModal && (
            <CancelCallModal
              onClose={handleCloseCancelCallModal}
              onCancelCall={handleCancelCall}
            />
          )}
          <button
            disabled={!callDetailsData?.phone_number}
            onClick={() => {
              handleNewTicketCall();
              checkExtensionStatus();
            }}
            className="flex items-center gap-3 p-4 dropdown-item font-semibold border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder"
          >
            <BusinessPhoneIcon className={"25px"} />
            <span>Call</span>
          </button>

          <ViewTicketOpt />
          <button
            onClick={() => {
              copyToClipboard(phoneNumber);
            }}
            className="flex items-center gap-3 p-4 dropdown-item font-semibold border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder "
          >
            <BusinessCopyIcon className={"23px"} />
            <a href="#" className="dropdown-link">
              Copy number
            </a>
          </button>

          {ticketStatus == "closed" ? (
            <button
              onClick={() => {
                setShowCloseTicketPopup(true);
                setIsTicketClosed(true);
              }}
              className="flex items-center gap-3 p-4 dropdown-item font-semibold border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder"
            >
              <BusinessCloseIcon size={"23px"} />
              <a href="#" className="dropdown-link">
                Re-Open ticket
              </a>
            </button>
          ) : (
            <div className="flex flex-col divide-y-[1px] dropdown-item font-semibold border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder">
              <button
                onClick={() => setShowCloseTicketPopup(true)}
                className="flex items-center gap-3 py-4 mx-4"
              >
                <BusinessCloseIcon size={"23px"} />
                <a href="#" className="dropdown-link">
                  Close ticket
                </a>
              </button>
            </div>
          )}
        </>
      );
    } else if (isScheduled && isRecent) {
      // Menu for calls that are both scheduled and recent
      // Here you can decide which menu to prioritize
      // For now, let's display the scheduled menu
      return (
        <>
          <button
            onClick={() => setShowCallPopup(true)}
            className="flex items-center gap-3 p-4 dropdown-item border-b border-gray-100-[1px] border-gray-100 w-full hover:bg-lightBg dark:hover:bg-darkModeBG dark:border-darkModeBorder"
          >
            <BusinessPhoneIcon className={"25px"} />
            <button
              disabled={!callDetailsData?.phone_number}
              onClick={() => {
                handleNewTicketCall();
                checkExtensionStatus();
              }}
            >
              Call
            </button>
          </button>
          <RescheduleOpt />
          <ViewTicketOpt />
          <CancelTicketbtn />
        </>
      );
    }
    // Optionally handle other cases
  };

  return (
    <>
      <div
        ref={dropdownRef}
        className={`flex justify-center hover:cursor-pointer relative`}
      >
        <a
          href="#"
          className="flex items-center pl-[15pt] text-xs transition duration-150 ease-in-out cursor-pointer "
          onClick={toggleDropdown}
        >
          <PiDotsThreeVerticalBold
            size={23}
            className=""
            color={isOpen ? "#4C5FEF" : ""}
          />
        </a>

        {isOpen && (
          <div
            className={`absolute z-20 font-semibold text-[12px] ${
              dropdownDirection === "up" ? "bottom-0 mb-10" : "top-2"
            } -right-3 border-[1px] border-gray-100 dark:border-darkModeBorder flex flex-col w-56 mt-8 bg-white rounded-md shadow-lg outline-none dark:bg-darkModeMain`}
          >
            {renderMenuItems()}
          </div>
        )}
      </div>
      <div className="z-50 ">
        {/* {showCallPopup && <PhoneDialer onClose={handleCloseCallPopup} />} */}
        {showCloseTicketPopup && (
          <CloseTicketPopup
            onClose={() => setShowCloseTicketPopup(false)}
            isTicketClosed={isTicketClosed}
            handleApiCall={() => {
              let handleApiCall = async () => {
                await updateStatusToClosed({
                  ticketId: ticket_id,
                  ticketStatus: ticketStatus,
                });
                await getApiCallHistory({
                  ticketId: ticket_id,
                  ticketStatus: ticketStatus,
                });
                setShowCloseTicketPopup(false);
              };
              handleApiCall();
            }}
          />
        )}

        {showRescheduleModal && (
          <RescheduleCallModal
            setSelectedScheduledTime={() => {}}
            onClose={handleCloseRescheduleModal}
            ticketId={callDetailsData?.id}
            scheduledTime={
              callDetailsData?.call_at_ts ||
              scheduledTime
            }
            closeModal={() => {
              setShowRescheduleModal(false);
            }}
            getScheduleCallData={getScheduleCallData}
          />
        )}
        {showCancelCallModal && (
          <CancelCallModal
            onClose={handleCloseCancelCallModal}
            onCancelCall={handleCancelCall}
            handleApiCall={() => {
              let handleApiCall = async () => {
                await cancelScheduleCallApi({
                  ticketId: callId,
                });
              };
              handleApiCall();
            }}
          />
        )}
      </div>
    </>
  );
}
