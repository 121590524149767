import { useLocation, useNavigate } from "react-router-dom";
import CallUs from "../../assets/CallUs.svg";
import Chat from "../../assets/Chat.svg";
import { MdOutlineAccessTime } from "react-icons/md";
import { useRef, useState } from "react";
import CallForMePopup from "./CallForMePopup";
import { CALL_NUMBER_CHECK } from "../../utils/constants";
import { sipCallParams } from "../../utils/DeviceDetails/SipDetails";
import toast from "react-hot-toast";
import { checkExtensionStatus } from "../atom/checkExtension";
import { AiChatApi } from "../../services/chat/AiChat";

export default function ContactOptions(props) {
  let { businessId, businessDetails, contactDetails } = props || {};
  let phoneNumberDetails = businessDetails?.data?.business_contacts;
  const checkExtension = useRef(true);
  const { clearState } = AiChatApi();
  const callingNumber =
    contactDetails?.contact ||
    phoneNumberDetails?.filter(
      (x) => x?.account_type_id == CALL_NUMBER_CHECK,
    )?.[0]?.account_value ||
    null;

  let navigate = useNavigate();
  const handleCallUsClick = async (businessId) => {
    navigate(`/business/call`, {
      state: {
        callingNumber: callingNumber,
        businessId: businessId,
        page: "calls",
        pageName: "businessProfile",
      },
    });
  };

  const handleRequestClick = (businessId) => {
    navigate("/chat", {
      state: {
        business_id: businessId,
        message_text: "To request a call, tell me your issue",
      },
    });
  };

  const handleCallForMeClick = (businessId) => {
    clearState();
    navigate(`/business/${businessId}`, {
      state: {
        business_id: businessId,
        message_text: "Request Call",
        page: "businessAI",
      },
    });
  };

  const handleNewTicketCall = async (event) => {
    checkExtension.current = false;
    if (!callingNumber) return;
    const sipCallData = sipCallParams(callingNumber);
    window.postMessage(sipCallData, "*");
  };

  return (
    <div className="flex flex-col items-center justify-center w-full pt-3 bg-white rounded-lg dark:bg-darkModeMain  dark:border-darkModeBorder">
      <div className="flex items-center justify-center w-full gap-3 ">
        <button
          disabled={!callingNumber}
          onClick={() => {
            handleNewTicketCall();
            checkExtensionStatus();
          }}
          className="min-w-[180px] justify-center hover:bg-lightBg dark:hover:bg-darkModeBG items-center py-4 pb-3 shadow-xl relative flex min-h-[158px] hover:cursor-pointer flex-col border border-gray-100 dark:border-darkModeBorder"
          style={{
            borderRadius: "20px",
            opacity: 1,
          }}
        >
        
          <div className="absolute border-[1px] border-gray-100 dark:border-darkModeBorder mt-2  rounded-lg z-[1000] bg-white dark:bg-darkModeMain flex items-center justify-center text-center m-1 p-0.5 -top-10">
            <button className="w-[40px] h-[40px] rounded-lg bg-white flex items-center justify-center ">
              <img src={CallUs} alt="Call us" width={45} height={45} />
            </button>
          </div>
          <p className="px-4 mt-8 text-sm font-semibold dark:text-white">
            I&apos;ll call
          </p>
          <p className="px-4 py-1 text-sm dark:text-white ">
            Call and speak with a service agent
          </p>
          <div className="w-[70px] h-[30px] m-2 bg-gradient-to-r bg-gray-200 dark:bg-darkModeBG gap-2 rounded-full flex items-center justify-center p-1">
            <MdOutlineAccessTime size={20} className="dark:text-white" />
            <p className="text-[11px] dark:text-white">15M</p>
          </div>
        </button>

        <button
          onClick={() => handleCallForMeClick(businessId)}
          className="min-w-[180px] justify-center hover:bg-lightBg dark:hover:bg-darkModeBG items-center py-4 pb-3 shadow-xl relative flex min-h-[158px] hover:cursor-pointer flex-col border border-gray-100 dark:border-darkModeBorder"
          style={{
            borderRadius: "20px",
            opacity: 1,
          }}
        >
          <div className="absolute border-[1px] border-gray-100 dark:border-darkModeBorder rounded-lg z-[1000] bg-white dark:bg-darkModeMain flex items-center justify-center text-center m-2 p-0.5 -top-10">
            <button className="w-[40px] h-[40px] rounded-lg bg-white flex items-center justify-center">
              <img src={Chat} alt="Call us" width={50} height={50} />
            </button>
          </div>

          <p className="px-4 pt-8 text-sm font-semibold">Request Call</p>
          <p className="px-4 py-1 text-sm">Request a call from the business.</p>
          <div className="w-[115px] h-[30px] m-2 bg-gradient-to-r from-[#2DC9EB] to-[#14D2B8] gap-2 rounded-full flex items-center justify-center p-1">
            <p className="text-white text-[11px] uppercase font-semibold">
              Recommended
            </p>
          </div>
        </button>
      </div>
    </div>
  );
}
