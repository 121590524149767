import React, { useEffect, useState } from "react";
import ResultsContainer from "./ResultsContainer";
import MapContainer from "./MapContainer";
import FiltersContainer from "./filter/FiltersContainer";
import businesses from "../../data/businesses";
import { useSearch } from "../../context/SearchContext";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import Subnav from "../navigation/Subnav";
import { FilterBlackIcon, FilterIcon } from "../icons/Icons";
import { LocationPin } from "../icons/SearchIcon";
import { sendMixpanelEvent } from "../../services/mixpanelService";

export default function SearchContainer() {
  const { searchTerm, selectedLocation, locationDetailsRef } = useSearch();

  const filteredBusinesses = businesses.filter((business) =>
    business?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
  );
  const { loadingSearch, topNavSearchDetailsData, loadingSearchData } =
    TopNavSearchService((state) => state);
  const { data } = topNavSearchDetailsData;
  const { searchDetailsData } = TopNavSearchService((state) => state);
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const [showFullSidebar, setShowFullSidebar] = useState(true);
  const [triggerApi, setTriggerApi] = useState(false);
  const [collapse, setCollapse] = useState(false);
  //On clicking filterIcon
  const filterClick = () => {
    setCollapse((prevCollapse) => !prevCollapse);
    setShowFullSidebar(true);
  };

  return (
    <div className="flex w-full pl-[80px] pt-[80px] dark:bg-darkModeMain bg-white overflow-hidden h-screen ">
      <div>
        <Subnav pageType={"Search"} />
      </div>
      <div className="flex w-full !pt-12">
        <div
          className={`overflow-y-auto transition-all duration-500 ease-in-out ${
            showFullSidebar ? "w-[20%]" : "w-0"
          }`}
        >
          <FiltersContainer
            setShowFullSidebar={setShowFullSidebar}
            collapse={collapse}
            triggerApi={triggerApi}
            setTriggerApi={setTriggerApi}
          />
        </div>

        <div
          className={`inline-block h-screen min-h-[1em] w-[1px] dark:bg-darkModeBG self-stretch bg-gray-200 transition-all duration-500 ease-in-out ${
            showFullSidebar ? "block" : "hidden"
          }`}
        ></div>

        {/* Center column - Results */}
        <div
          className={`overflow-y-none  transition-all duration-500 h-full ease-in-out ${
            showFullSidebar ? "w-[50%]" : "w-full"
          }`}
        >
          <div className="flex flex-row items-center p-4 justify-between  dark:bg-darkModeBG  bg-ibluebg">
            <div className="flex ">
              <div
                className={`p-2 rounded-lg  border-[1px] border-darkModeBorder relative ${
                  showFullSidebar ? "bg-ipurple" : ""
                }`}
                onClick={() => {
                  sendMixpanelEvent("Filter Selection Initiated", {
                    Type: "Business Search",
                  });
                  filterClick();
                }}
              >
                <div className="absolute border-[1px]  border-gray-100  flex text-white justify-center border-ipink shadow-xl shadow-black  items-center text-[8px] -right-1 -top-1 rounded-full w-[15px] font-semibold bg-ipink h-[15px]">
                  2
                </div>
                {showFullSidebar ? <FilterIcon /> : <FilterBlackIcon />}
              </div>
              <div className="rounded-lg border-gray-100 border-[1px] dark:border-darkModeBorder dark:bg-darkModeBG font-semibold text-[14px] flex items-center ml-5 bg-white !px-2 !pr-3">
                <LocationPin />
                {locationDetailsRef?.current || "Current Location"}
              </div>
            </div>
            <div className="px-4"></div>
          </div>
          <ResultsContainer
            businesses={filteredBusinesses}
            searchData={searchDetailsData?.data}
            setSelectedMarkerId={(data) => setSelectedMarkerId(data)}
            selectedMarkerId={selectedMarkerId}
          />
        </div>

        {/* Vertical Divider */}
        <div
          className={`inline-block h-screen min-h-[1em] w-[1px] dark:bg-darkModeBG self-stretch bg-gray-200 transition-all duration-500 ease-in-out ${
            showFullSidebar ? "block" : "hidden"
          }`}
        ></div>

        {/* Right column - Map */}
        <div
          className={`transition-all duration-500 ease-in-out ${
            showFullSidebar ? "w-[35%]" : "w-full"
          }`}
        >
          <MapContainer
            mapSearchList={data}
            selectedMarkerId={selectedMarkerId}
            setSelectedMarkerId={(data) => setSelectedMarkerId(data)}
            setTriggerApi={(data) => setTriggerApi(data)}
          />
        </div>
      </div>
    </div>
  );
}
