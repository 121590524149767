import NoImageFound from "../../assets/NoImageFound.jpg";

export const InfoIcon = ({ className }) => {
  return (
    <svg
      className={`svg-icon ${className} text-black dark:text-white`}
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Icons/Info"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M15,26 C21.0176471,26 26,21.0068627 26,15 C26,8.98235294 21.0068627,4 14.9892157,4 C8.98235294,4 4,8.98235294 4,15 C4,21.0068627 8.99313725,26 15,26 Z M15,24.1666667 C9.90980392,24.1666667 5.84411765,20.0901961 5.84411765,15 C5.84411765,9.90980392 9.89901961,5.83333333 14.9892157,5.83333333 C20.0794118,5.83333333 24.1558824,9.90980392 24.166688,15 C24.177451,20.0901961 20.0901961,24.1666667 15,24.1666667 Z M14.9029412,11.1607843 C15.6901961,11.1607843 16.304902,10.5352941 16.304902,9.75882353 C16.304902,8.97156863 15.6901961,8.34607843 14.9029412,8.34607843 C14.1264706,8.34607843 13.5009804,8.97156863 13.5009804,9.75882353 C13.5009804,10.5352941 14.1264706,11.1607843 14.9029412,11.1607843 Z M16.4019608,20.9313725 C16.8441176,20.9313725 17.1892157,20.6186275 17.1892157,20.1764706 C17.1892157,19.7558824 16.8441176,19.4215686 16.4019608,19.4215686 L16.1647059,19.4215686 L16.1647059,13.9323529 C16.1647059,13.35 15.8735294,12.9617647 15.3235294,12.9617647 L14.3960784,12.9617647 C13.9539216,12.9617647 13.6088235,13.2960784 13.6088235,13.7166667 C13.6088235,14.1588235 13.9539216,14.4715686 14.3960784,14.4715686 L14.45,14.4715686 L14.45,19.4215686 L14.2127451,19.4215686 C13.7705882,19.4215686 13.4254902,19.7558824 13.4254902,20.1764706 C13.4254902,20.6186275 13.7705882,20.9313725 14.2127451,20.9313725 L16.4019608,20.9313725 Z"
          id="􀅴"
          fill="currentColor"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const TeamInfoIcon = ({ className, imageSrc }) => {
  return (
      <img
          src={imageSrc || NoImageFound}
          className={`svg-icon ${className}`}
          alt="TeamInfo logo"
        />
    )
};
