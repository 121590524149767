import { useEffect, useRef, useState } from "react";
import { LuPencil } from "react-icons/lu";

const EditableText = (props) => {
  let { initialText, onChangeText, triggerSaveChanges } = props || {};

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

  const handleEditClick = () => {
    setIsEditing(true);
    setText(initialText);
  };
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        triggerSaveChanges(text);
        setIsEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, text]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      triggerSaveChanges(text);
      setIsEditing(false);
    }
  };

  return (
    <div className="flex items-center justify-between w-full px-4 pt-0 dark:border-darkModeBorder -pt-10 ">
      <div
        className={
          isEditing
            ? "flex items-center w-full gap-2 font-semibold pb-2 "
            : "flex items-center w-full gap-2 font-semibold py-2 "
        }
      >
        {isEditing ? (
          <>
            <input
              ref={inputRef}
              type="text"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                onChangeText(e.target.value);
              }}
              onKeyDown={handleKeyPress}
              className="rounded-lg bg-inherit w-full"
            />
          </>
        ) : (
          <p className="text-[16px] capitalize">{initialText}</p>
        )}
        {!isEditing && (
          <button onClick={handleEditClick}>
            <LuPencil size={15} />
          </button>
        )}
      </div>
    </div>
  );
};

export default EditableText;
