import PropTypes from "prop-types";

ScrollDropdown.propTypes = {
  setContactType: PropTypes.any,
  contactTypeResp: PropTypes.any,
  contactType: PropTypes.any,
};
export default function ScrollDropdown(props) {
  const { setContactType, contactTypeResp, contactType } = props;
  let optionArray = Array?.isArray(contactTypeResp?.data)
    ? contactTypeResp?.data
    : [];
  const handleChange = (event) => {
    setContactType(
      optionArray?.filter((data) => data?.id == event?.target?.value)[0]
    );
  };
  return (
    <div className="w-[410px] h-[55px] overflow-y rounded-lg dark:hover:bg-darkModeBG bg-white dark:bg-darkModeMain flex flex-col border-1  border-gray-100 dark:border-darkModeBorder">
      <select
        name="category_id"
        value={contactType?.id}
        onChange={handleChange}
        className="w-[420px] h-[55px] border-[1px] placeholder:text-gray-300 border-gray-200 dark:border-darkModeBorder rounded-lg bg-inherit text-sm px-2 active:border-ipurple active:ring-ipurple active:outline-transparent focus:outline-transparent focus:ring-transparent focus:border-ipurple  "
      >
        {Array?.isArray(optionArray) &&
          optionArray?.map((item) => (
            <option key={item?.id} value={item?.id}>
              {item?.name}
            </option>
          ))}
      </select>
    </div>
  );
}
