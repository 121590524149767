import { useEffect, useState } from "react";
import { TicketsDetails } from "../../../services/tickets/TicketDetails";

export default function CallDataView() {
  const [callData, setCallData] = useState({});
  const { listSingleTicketDetails } = TicketsDetails((state) => state);

  useEffect(() => {
    if (listSingleTicketDetails?.success) {
      setCallData(listSingleTicketDetails?.data?.call_data);
    }
  }, [listSingleTicketDetails]);

  return (
    <div className="flex flex-col items-center justify-center w-full pt-1">
      <div className="w-full h-screen pl-3 bg-white rounded-lg border-[1px]  border-gray-100  dark:bg-darkModeMain dark:border-darkModeBorder">
        <ul className="list-disc pl-5 mt-5">
          {callData &&
            Object.entries(callData).map(([key, value]) => (
              <li className="mb-2" key={key}>
                <span className="font-semibold">
                  {key.replace(/\?$/, "")} :
                </span>{" "}
                {value}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
