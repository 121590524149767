import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
import plus from "../../../../src/assets/icons/Plus.svg";
import plus2 from "../../../../src/assets/icons/Plus Copy 2.svg";

export default function SavedCallFlowModal({ onClose }) {
  return ReactDOM.createPortal(
    <div className="w-full h-screen fixed bg-[#140F26] bg-opacity-[95%] flex flex-col justify-center items-center">
      <div className=" w-[680px] h-[460px] justify-center items-center flex flex-col bg-white dark:bg-darkModeMain dark:border-[1px] border-gray-100 dark:border-darkModeBorder rounded-lg relative text-isearch">
        <div className="w-full h-[60px] flex justify-between border-b dark:border-darkModeBorder border-gray-100 items-center">
          <h1 className="m-5 font-semibold uppercase dark:text-white">
            Verify Coverage
          </h1>
          <button
            onClick={onClose}
            className="absolute top-4 right-4 dark:text-white"
          >
            <IoClose size={25} />
          </button>
        </div>

        <div className="w-full h-full py-5 flex flex-col flex-start border-t-[1px] dark:border-darkModeBorder">
          <div className="mr-10">
            <p className="font-sans font-normal mx-5 mb-5 mr-20 text-[14px] dark:text-white">
              iAllo will use this saved call flow. You can change the input data
              if there is any <br />
              but not the buttons pressed in IVR
            </p>
          </div>

          <div className="flex flex-col items-start px-5 w-full ">
            <div className="w-full h-12 border border-light-gray dark:border-darkModeBorder flex items-center text-sm rounded-md text-left pl-2 mb-2 ">
              <img src={plus} className="theme-icon" alt="Plus Icon" />
              <span className="mr-2 font-semibold text-black dark:text-white">
                Press 2{" "}
              </span>{" "}
              <span className="text-black font-sans dark:text-white">
                for provider services
              </span>
            </div>
            <div className="w-full h-12 border border-light-gray dark:border-darkModeBorder flex items-center rounded-md text-sm text-left pl-2 mb-2">
              <img src={plus} className="theme-icon" alt="Plus Icon" />
              <span className="font-semibold mr-2 text-black dark:text-white">
                Press 3{" "}
              </span>
              <span className="text-black font-sans dark:text-white">
                "FOR REIMBURSEMENT ISSUES"
              </span>
            </div>

            <div className="w-full h-12 border border-light-gray dark:border-darkModeBorder flex items-center rounded-md text-sm text-left pl-2 mb-2">
              <img src={plus2} className="theme-icon" alt="Enter" />
              <span className="font-semibold mr-2 text-black  dark:text-white ">
                Enter{" "}
              </span>{" "}
              <span className="text-black font-sans  dark:text-white ">
                Your provided number
              </span>
              <div className="ml-2 h-7 inline-flex border  dark:text-white  border-light-gray dark:border-darkModeBorder items-center justify-center text-black font-semibold rounded-md px-2">
                {/* Add any text here */}
                112233
              </div>
            </div>
            <div className="w-full h-12 border border-light-gray dark:border-darkModeBorder flex items-center rounded-md text-sm text-left pl-2 mb-2">
              <img src={plus} className="theme-icon" alt="Plus Icon" />
              <span className="font-semibold mr-2 text-black  dark:text-white ">
                Press 1{" "}
              </span>{" "}
              <span className="text-black font-sans  dark:text-white ">
                to speak with a representative
              </span>
            </div>
          </div>
          <div className="flex  mt-5 justify-between px-5 cursor-pointer">
            <button
              onClick={onClose}
              className="w-[200px] h-[45px] rounded-full border-black dark:bg-black hover:dark:bg-white hover:dark:text-black dark:text-white border-[1px] text-black hover:bg-[#383348] hover:text-white hover:border-[#383348] uppercase text-sm ml-[20px] mr-[15px]"
            >
              Cancel
            </button>
            <button
              style={{ marginBottom: 24 }}
              className="w-[200px] h-[45px]  uppercase rounded-full bg-ipurple text-white hover:border-[1px] border-gray-100 hover:border-ipurple hover:text-ipurple hover:bg-inherit mr-[15px]"
            >
              Call
            </button>
          </div>
        </div>
      </div>
    </div>,

    document.getElementById("portal-root"),
  );
}
