import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";

export const CallTemplateService = create((set, get) => ({
  callTemplateList: [],
  loadingCallTemplateList: false,
  loading: false,
  error: null,

  listCallTemplateApi: async (queryParams = {}) => {
    try {
      set({ loading: true });
      const params = new URLSearchParams(queryParams).toString();

      const response = await AxiosApi.get(
        `call-flows/call-templates${params ? `?${params}` : ""}`,
      );

      const { success, message, data } = response?.data || { message: "error" };

      if (success) {
        set({
          loadingCallTemplateList: false,
          callTemplateList: data,
        });

        return data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loading: false, error: err });
      return err;
    }
  },
}));
