import { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { ValidateNumberOnly } from "../../utils/validate/NumberValidation";
import countryData from "../../utils/country-code/countrycode.json";
import { CgClose } from "react-icons/cg";

export default function CountryCodeInput(props) {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const {
    setPhoneNumber,
    phoneNumber = "",
    setCountryCode,
    countryCode,
    setTimezone,
    onPhoneInputSubmit,
    setSelectedCountryCode,
    editable = true,
    readonly,
  } = props || {};

  const [selectedCountry, setSelectedCountry] = useState(
    countryData.find((country) => country.dial_code === "+1") || countryData[0],
  );
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (typeof setTimezone === "function") {
      setTimezone(timezone);
    }

    if (typeof countryCode === "string") {
      const country = countryData.find(
        (country) => country.dial_code === countryCode,
      );

      if (typeof country == "object") {
        setSelectedCountry(country);
        setCountryCode(country.dial_code);
      }
      setIsDropdownVisible(false);
    }
  }, [countryCode]);

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  const selectCountry = (country) => {
    setSelectedCountry(country);
    setCountryCode(country.dial_code);
    setIsDropdownVisible(false);
    if (setSelectedCountryCode) {
      setSelectedCountryCode(country?.code || "US");
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      setIsDropdownVisible(false);
    }
  };

  const renderInput = (disabled) => (
    <div
      className={`flex w-[320px] dark:border-darkModeBG ${
        disabled ? "cursor-not-allowed opacity-50" : ""
      }`}
    >
      <input
        type="text"
        name="phone-number"
        id="phone-number"
        className={`border-0 w-full rounded-md focus:ring-transparent text-black dark:text-white dark:bg-darkModeMain dark:border-darkModeBorder ${
          disabled ? "cursor-not-allowed opacity-50" : ""
        }`}
        placeholder="(555) 987-6543"
        maxLength={11}
        value={phoneNumber}
        autoComplete="off"
        onChange={(e) => setPhoneNumber(ValidateNumberOnly(e.target.value))}
        disabled={disabled || !editable}
        readOnly={readonly || !editable}
      />
      <div
        className={`w-[10%] flex justify-center items-center pr-1 mt-4${
          disabled ? "cursor-not-allowed opacity-50" : ""
        }`}
      >
        {phoneNumber.length !== 0 && readonly && (
          <CgClose
            className="mb-3"
            size={30}
            onClick={() => setPhoneNumber("")}
          />
        )}
      </div>
    </div>
  );

  return (
    <div
      className={`relative w-full mt-2 flex text-sm font-semibold border border-gray-200 dark:border-darkModeBorder  rounded-md  ${
        !editable ? "cursor-not-allowed " : ""

      }`}
    >
      <div
        className={`flex items-center relative bg-transparent border-0 rounded-md ${
          !editable ? "cursor-not-allowed opacity-50 pointer-events-none" : ""
        }`}
        onClick={editable ? toggleDropdown : undefined}
      >
        <span className="py-0 pl-3 text-2xl">{selectedCountry.emoji}</span>
        <input
          type="text"
          id="country-code"
          name="country-code"
          placeholder="+1"
          className={`h-[55px] py-0 text-base z-30 font-semibold w-[80px] text-black dark:text-white bg-transparent border-0 focus:ring-0 ${
            !editable ? "cursor-not-allowed " : ""
          }`}
          value={countryCode}
          autoComplete="off"
          maxLength={6}
          onChange={
            editable ? (e) => setCountryCode(e.target.value) : undefined
          }
          onKeyDown={editable ? handleKeyDown : undefined}
          readOnly={readonly || !editable}
        />
        <button
          ref={buttonRef}
          className={`z-40 ${
            !editable ? "cursor-not-allowed opacity-50 pointer-events-none" : ""
          }`}
        >
          {isDropdownVisible ? (
            <HiChevronUp size={20} />
          ) : (
            <HiChevronDown size={20} />
          )}
        </button>
        <div className="w-[1px] h-[35px] bg-gray-200 ml-4  dark:bg-darkModeBorder"></div>
      </div>

      {isDropdownVisible && editable && (
        <div
          className={`absolute z-10 overflow-auto text-black dark:text-white bg-white dark:bg-darkModeMain border border-gray-200 dark:border-darkModeBorder rounded-md mt-14 max-h-60 w-full`}
          ref={dropdownRef}
        >
          {countryData
            .filter(
              (data) =>
                data.dial_code.includes(countryCode) || countryCode === "",
            )
            .map((country) => (
              <div
                key={country.code}
                className="flex items-center p-2 cursor-pointer  dark:hover:bg-darkModeBG hover:bg-gray-100"
                onClick={() => selectCountry(country)}
              >
                <span className="mr-2 text-2xl">{country.emoji}</span>
                {country.name} ({country.dial_code})
              </div>
            ))}
        </div>
      )}

      {renderInput(!editable)}
    </div>
  );
}
