import { useEffect, useRef, useState } from "react";
import SearchModal from "./SearchModal";
import { TopNavSearchService } from "../../../services/search/topNav/TopNavSearchService";
import { DEFAULT_LOCATION_DISTANCE } from "../../../utils/constants";
import { DirectoryDetails } from "../../../services/directory/DirectoryDetails";
import { PrivateLockIcon } from "../../icons/Icons";
import { MdVerified } from "react-icons/md";
import { TbLockCancel } from "react-icons/tb";
import { formatInternationalPhoneNumber } from "../../../utils/numberFormat";
import { optionIcon } from "../../business-profile/ContactsCard";
import { AiChatApi } from "../../../services/chat/AiChat";
import {
  extensionLogin,
  sipCallParams,
} from "../../../utils/DeviceDetails/SipDetails";
import ContactBusinessCard from "../ContactBusinessCard";
import dayjs from "dayjs";
import RescheduleCallModal from "../../calls/RescheduleCallModal";
import { checkExtensionStatus } from "../../atom/checkExtension";
import { CallDetailsService } from "../../../services/calls/CallDetailsService";
import { getScheduledTime } from "../../../utils/constants";
import CallPopup from "../../calls/CallPopup";

export default function SearchChatMessage(props) {
  const scheduledTime = getScheduledTime();
  const { chatMessage, initiateSocketChat } = props || {};
  const { addCallRequestApi } = CallDetailsService((state) => state);
  const [selectedContactId, setSelectedContactId] = useState("");
  const { topNavDirectorySearchApi } = TopNavSearchService((state) => state);
  const { fetchBusinessDetailsApi } = DirectoryDetails((state) => state);
  const [contactData, setContactData] = useState([]);
  const selectedScheduledTime = useRef(scheduledTime);
  const [showModal, setShowModal] = useState({ id: null, open: false });
  const [showRescheduleCallModal, setShowRescheduleCallModal] = useState(false);
  const { getShowChatDetailsApi, getShowChatDetails } = AiChatApi(
    (state) => state,
  );
  const [showCallPopup, setShowCallPopup] = useState(false);

  const [showSearchView, setShowSearchView] = useState(false);
  const btnClassName =
    "h-[45px] w-[305px] rounded-full border-[1px]  border-gray-100 text-[12px] font-semibold text-[black]";
  const handleSearch = (searchText, distance) => {
    setShowSearchView(true);
    topNavDirectorySearchApi({
      LocationDistance:
        distance && distance != "null" ? distance : DEFAULT_LOCATION_DISTANCE,
      keyword: searchText,
    });
  };
  // close extension popup
  const handleCallPopUpClose = () => {
    setShowCallPopup(false);
  };

  // handle call extension
  const handleCallExtension = () => {
    const isExtensionInstalled = localStorage.getItem("isExtension");
    if (!isExtensionInstalled) {
      extensionLogin();
      setShowCallPopup(!showCallPopup);
    }
  };
  const scrollRef = useRef(null);
  useEffect(() => {
    // scroll to the bottom of the chat container
    if (showModal.open) {
      scrollRef.current?.scrollIntoView({ behavior: "auto" });
      handleCallExtension();
    }
  }, [showModal]);

  const handleMessageBox = (data) => {
    if (data?.name == "Website") {
      window.open(data?.contact, "_blank");
    }
  };
  const MessageBox = () => {
    return (
      <div className="flex justify-center flex-col w-[40%]">
        {contactData?.length !== 0 &&
          Array?.isArray(contactData) &&
          contactData?.map((contact, index) => {
            const ids = index + 1;
            return (
              <div key={ids}>
                <div className="relative w-full my-4 text-[black] dark:text-white">
                  <button
                    className=" min-h-[70px] w-full flex justify-between items-center px-3 hover:bg-lightBg dark:hover:bg-darkModeBG  rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder"
                    onClick={() => {
                      setSelectedContactId(contact?.id);
                      if (contact?.name == "Phone") {
                        setShowModal({ open: true, id: ids });
                      } else {
                        handleMessageBox(contact);
                      }
                    }}
                  >
                    <div className="flex items-center w-full ">
                      <div className="flex flex-col text-sm w-full  py-2 align-middle">
                        <div className="flex items-center">
                          <div className="pr-3 flex items-center">
                            {contact?.icon && (
                              <contact.icon
                                size={26}
                                className={"text-black dark:text-white"}
                              />
                            )}
                            {contact?.isLocked && (
                              <TbLockCancel
                                size={26}
                                className={"!text-black dark:text-white"}
                              />
                            )}
                          </div>
                          {contact?.name == "Website" ? (
                            <div>
                              <div className="flex gap-2 flex-col pr-2  w-auto">
                                <p className="font-bold  w-full text-wrap text-left break-all flex">
                                  {contact?.contact}
                                  {contact?.isVerified && (
                                    <div className="flex items-center">
                                      <MdVerified
                                        color="#48CFFF"
                                        size={20}
                                        className=""
                                      />
                                    </div>
                                  )}{" "}
                                  {contact?.isPrivate && (
                                    <div className="flex items-center pl-2">
                                      <PrivateLockIcon size={24} />
                                    </div>
                                  )}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center justify-start gap-2 flex-col pr-3">
                              <p className="font-bold truncate w-full flex">
                                {contact?.contact}{" "}
                                {contact?.isVerified && (
                                  <div className="flex items-center pl-2">
                                    <MdVerified
                                      color="#48CFFF"
                                      size={20}
                                      className=""
                                    />
                                  </div>
                                )}{" "}
                                {contact?.isPrivate && (
                                  <div className="flex items-center">
                                    <PrivateLockIcon size={24} />
                                  </div>
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
                {showModal?.open && showModal?.id == ids && (
                  <div className="w-full flex justify-center" ref={scrollRef}>
                    <ContactBusinessCard
                      userData={{
                        phone: contact?.contact
                          ?.replaceAll("-", "")
                          .replaceAll(" ", "")
                          ?.replaceAll("+", ""),
                        leftBoxText: "I'll call",
                        rightBoxText: "Request call ",
                      }}
                      handleCancelBtn={() => {
                        setShowModal({ open: false, id: null });
                      }}
                      handleCallNow={() => {
                        let currentGivenTime = dayjs();
                        currentGivenTime = currentGivenTime.add(5, "minutes");
                        const dates = dayjs(currentGivenTime)
                          .utc()
                          .format("YYYY-MM-DDTHH:mm:ss");
                        initiateSocketChat("initiateChat");
                        setTimeout(() => {
                          addCallRequestApi({
                            business_contact_id: selectedContactId,
                            business_id:
                              getShowChatDetails?.chat?.business_id ||
                              chatMessage?.message_json?.business_id,
                            call_at_ts: dates + ".000Z",
                            chat_id: chatMessage?.chat_id,
                          });
                        }, 1000);
                      }}
                      pageType="SearchChatMessage"
                      callInitiate={() => {
                        const sipCallData = sipCallParams(
                          contact?.contact
                            ?.replaceAll("-", "")
                            .replaceAll(" ", "")
                            ?.replaceAll("+", ""),
                        );
                        window.postMessage(sipCallData, "*");
                        checkExtensionStatus();
                      }}
                      setShowRescheduleCallModal={() => {
                        setShowRescheduleCallModal(true);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  };
  useEffect(() => {
    if (chatMessage?.message_json?.business_id) {
      fetchBusinessDetailsApi({
        businessId: chatMessage.message_json?.business_id,
      }).then((data) => {
        if (data?.data?.business_contacts) {
          setContactData(
            (data?.data?.business_contacts?.length !== 0 &&
              data?.data?.business_contacts?.map((contacts) => {
                const formattedContact = {
                  id: contacts?.id || "",
                  name: contacts?.account_type?.name || contacts?.title || "",
                  icon:
                    optionIcon[
                      contacts?.account_type?.name || contacts?.icon
                    ] || "",
                  contact: contacts?.account_value || "",
                  hasTime: contacts?.is_hasTime || contacts?.contact || "",
                  isVerified: contacts?.is_verified || "",
                  isLocked: contacts?.is_locked || "",
                  isPrivate: contacts?.is_private || "",
                };
                if (formattedContact?.name === "Phone") {
                  formattedContact.contact = formatInternationalPhoneNumber(
                    formattedContact?.contact,
                  );
                }
                return formattedContact;
              })) ||
              [],
          );
        }
      });
    }
  }, [chatMessage?.message_json]);

  useEffect(() => {
    if (getShowChatDetails?.chat?.business?.id) {
      setContactData(
        (getShowChatDetails?.chat?.business?.business_contacts?.length !== 0 &&
          getShowChatDetails?.chat?.business?.business_contacts?.map(
            (contacts) => {
              const formattedContact = {
                id: contacts?.account_type_id || "",
                name: contacts?.account_type?.name || contacts?.title || "",
                icon:
                  optionIcon[contacts?.account_type?.name || contacts?.icon] ||
                  "",
                contact: contacts?.account_value || "",
                hasTime: contacts?.is_hasTime || contacts?.contact || "",
                isVerified: contacts?.is_verified || "",
                isLocked: contacts?.is_locked || "",
                isPrivate: contacts?.is_private || "",
              };
              if (formattedContact?.name === "Phone") {
                formattedContact.contact = formatInternationalPhoneNumber(
                  formattedContact?.contact,
                );
              }
              return formattedContact;
            },
          )) ||
          [],
      );
    }
  }, [getShowChatDetails]);
  const renderButtons = (messageType, messageJson, selectedMsgId) => {
    if (messageType === "search" && messageJson?.total_records > 1) {
      return (
        <div className="w-full flex justify-center">
          {!getShowChatDetails?.chat?.business?.id ? (
            <button
              className={`${btnClassName} border-[1px]  border-black mt-3 justify-center dark:text-white`}
              onClick={() =>
                handleSearch(messageJson?.search_text, messageJson?.radius)
              }
            >
              Show Results
            </button>
          ) : (
            <MessageBox />
          )}
        </div>
      );
    } else if (messageType === "search" && messageJson?.total_records == 1) {
      return (
        <div className="w-full flex justify-center">
          <MessageBox />
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row items-center justify-start w-full py-1 text-white">
      {renderButtons(
        chatMessage.message_type,
        chatMessage.message_json,
        chatMessage,
      )}
      {showSearchView && (
        <SearchModal
          handleModal={(data) => setShowSearchView(data)}
          closeModal={(data) => {
            setShowSearchView(data);
            getShowChatDetailsApi({
              id: chatMessage?.chat_id,
            });
          }}
          chatId={chatMessage?.chat_id}
        />
      )}

      {showRescheduleCallModal && (
        <RescheduleCallModal
          pageType="AIchat"
          onClose={(data) => {
            setShowRescheduleCallModal(false);
            initiateSocketChat("initiateChat");
            addCallRequestApi({
              business_contact_id: selectedContactId,
              business_id:
                getShowChatDetails?.chat?.business_id ||
                chatMessage?.message_json?.business_id,
              call_at_ts: data,
              chat_id: chatMessage?.chat_id,
            });
          }}
          closeModal={() => {
            setShowRescheduleCallModal(false);
          }}
          selectedScheduledTime={selectedScheduledTime.current}
          setSelectedScheduledTime={(data) => {
            selectedScheduledTime.current = data;
          }}
          businessId={""}
          scheduledTime={scheduledTime}
          showSchedule={true}
        />
      )}
      {showCallPopup && <CallPopup onClose={handleCallPopUpClose} />}
    </div>
  );
}
