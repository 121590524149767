import Avatar from "../Avatar";
import { userDetails } from "../../routes/ProtectedRoutes";
import User from "../../assets/icons/User.svg"

export default function UserMessage(props) {
  let { userMessage } = props || {};

  const userDetailsProfile = userDetails();
  if(userMessage === "Invalid Date"){
    return;
  }
  return (
    <div className="flex flex-row items-center justify-end w-full ">
      {userMessage ? (
        <div className="flex max-w-[45%] gap-2">
          <p className="flex-1 w-full p-4 mb-3 text-sm text-black rounded-b-lg rounded-tl-lg bg-gradient-to-r from-gray-300 to-slate-200 overflow-wrap break-word">
            {userMessage}
          </p>
          <Avatar
            pathSrc={userDetailsProfile?.upload?.file_path || User}
            className={"!h-[50px] !w-[50px]"}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
