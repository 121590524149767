import { AiOutlinePaperClip } from "react-icons/ai";
import { useEffect, useState } from "react";
import { AiChatApi } from "../../services/chat/AiChat";
import { ChatUPArrow } from "../icons/Icons";

export default function ChatForm(props) {
  let {
    handleSubmit,
    setInputValue,
    inputValue,
    selectedFiles,
    setSelectedFiles,
    hitShowChatApi,
    setClick,
    handleLoader,
    chat,
    setChat,
    chatId,
  } = props || {};

  const { createAttachImagesToChatApi, ChatDetails } = AiChatApi(
    (state) => state,
  );

  const handleAttachmentApi = async () => {
    const data = {
      files: selectedFiles,
      note_type: "",
      title: "",
      description: "",
    };
    handleLoader(true);
    await createAttachImagesToChatApi(chatId, data);
    setClick(true);
    await handleSubmit();
    await hitShowChatApi();
  };

  useEffect(() => {
    if (selectedFiles?.length > 0 && chatId) {
      handleAttachmentApi();

      // Corrected: Added parentheses for function call
    }
  }, [selectedFiles, chatId]);
  const [inputElement, setInputElement] = useState(null);

  return (
    <div className="w-full rounded-br-lg z-50 border-t-[1px] border border-gray-200  border-l-0 dark:border-darkModeBorder dark:bg-darkModeMain bg-white px-5 py-[16.5px] flex justify-center items-center">
      <div className="z-10 relative flex items-center  w-full focus-within:ring focus-within:ring-ipurple  bg-gray-100 h-[42px] rounded-full dark:border-darkModeBorder dark:bg-darkModeMain">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={inputValue === "" ? "Type here..." : ""}
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.target.value !== "") {
              setSelectedFiles([]);
              handleSubmit();
            }
          }}
          className="w-full pl-4 pr-10 rounded-full border-none dark:bg-darkModeBorder  text-black dark:text-white  bg-gray-100"
        />

        {/* File input and button */}
        <input
          type="file"
          accept="image/jpg ,image/jpeg,image/png,pdf,application/pdf,application/vnd.ms-excel"
          name="myImage"
          ref={(input) => setInputElement(input)}
          disabled={!chatId}
          className="hidden"
          onChange={(e) => {
            const chosenFiles = Array.prototype.slice.call(e.target.files);
            setSelectedFiles([...selectedFiles, ...chosenFiles]);
          }}
          multiple
        />
        <button
          className={`absolute bottom-3 right-4 ${
            chatId && chat
              ? "text-black dark:text-white hover:cursor-pointer"
              : "text-gray-300 hover:cursor-auto "
          }`}
          onClick={(e) => {
            if (chatId && inputElement) {
              inputElement.click(); // Manually trigger file input click
            }
          }}
        >
          <AiOutlinePaperClip size={24} className=" -mb-1" />
        </button>
      </div>
      <button
        className="flex m-2 row"
        onClick={() => {
          if ((inputValue || selectedFiles) && inputValue?.length != 0) {
            setChat(true);
            handleSubmit();
          }
        }}
      >
        <ChatUPArrow />
      </button>
    </div>
  );
}
