import { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import NotesMenu from "./NotesMenu";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import { getTimeDiffDetails } from "../../utils/TimeDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteNotePopup from "./DeleteNotePopup";
import ScrollingLoader from "../atom/loader/scrollingLoader";

export default function NotesCards(props) {
  let {
    notes,
    setSelectedNoteId,
    setSelectedContainer,
    businessId,
    renderParentData,
    onDelete,
    currentPage,
    activeIndex,
    setActiveIndex,
  } = props || {};
  const {
    deleteNotesApi,
    notesPinnedApi,
    loadingTicketDetails,
    noteDetailsLastPage,
  } = TicketsDetails((state) => state);
  const [pinDropdown, setPinDropdown] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);

  const handleShowNotesMenu = (noteId, event) => {
    setPinDropdown(true);
    event?.stopPropagation();
    setActiveIndex((prevNoteId) => (prevNoteId === noteId ? null : noteId));
  };

  const handleRemoveNote = (noteId) => {
    setShowDeletePopup(true);
    setNoteToDelete(noteId);
  };

  const confirmDeletion = async () => {
    await deleteNotesApi({
      businessId: businessId,
      noteId: noteToDelete,
    });

    onDelete();
    setActiveIndex(null);
    setPinDropdown(false);
    setShowDeletePopup(false);
  };

  const handlePinNote = async (noteId, pinStatus) => {
    await notesPinnedApi({
      businessId: businessId,
      noteId: noteId,
      pinnedStatus: {
        is_pinned: !pinStatus,
      },
    });
    setPinDropdown(false);
  };

  const dropdownRef = useRef(null);
  const excludedComponents = [dropdownRef];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !excludedComponents.some((component) =>
          component.current.contains(event.target)
        )
      ) {
        setPinDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, excludedComponents]);
  const onClose = () => {
    setShowDeletePopup(false);
  };

  return (
    <>
      <div
        id="NoteScrollableDiv"
        className="w-full flex-col justify-center items-center overflow-auto divide-y-[1px] dark:divide-darkModeBorder border-b border-gray-100-[1px] dark:border-darkModeBorder"
      >
        <InfiniteScroll
          dataLength={notes?.length}
          next={renderParentData}
          hasMore={currentPage <= noteDetailsLastPage}
          scrollableTarget="NoteScrollableDiv"
          loader={loadingTicketDetails ? <ScrollingLoader /> : <></>}
        >
          {notes?.length !== 0 &&
            notes?.map((note) => (
              <div

                key={note?.id}
                onClick={() => {
                  setSelectedNoteId(note);
                  setSelectedContainer(note);
                }}
                className={`w-full h-[75px] min-h-[70px] hover:bg-lightBg dark:hover:bg-darkModeBG flex items-center cursor-pointer ${
                  activeIndex === note?.id
                    ? "text-ipurple dark:bg-darkModeBG"
                    : ""
                }`}
              >
                {" "}
                <div className="flex items-center justify-between w-full p-4">
                  <div className="flex flex-col px-2">
                    <p className="font-semibold line-clamp-1">{note?.title}</p>
                    <p className="text-gray-400">
                      {getTimeDiffDetails(note?.created_at)}
                    </p>
                  </div>
                  <div className="flex">
                    {note?.uploads?.[0]?.file_path ? (
                      <img
                        src={note?.uploads?.[0]?.file_path}
                        width={65}
                        height={65}
                        className="min-w-[40px] min-h-[40px] max-h-[65px] max-w-[65px] object-cover rounded mr-5"
                      />
                    ) : (
                      <></>
                    )}
                    <div className="relative items-center flex">
                      <button
                        onClick={(event) =>
                          handleShowNotesMenu(note?.id, event)
                        }
                      >
                        <BsThreeDotsVertical size={18} />
                      </button>
                      {activeIndex === note?.id && pinDropdown && (
                        <NotesMenu
                          dropdownRef={dropdownRef}
                          pinId={note.is_pinned}
                          onRemove={() => handleRemoveNote(note?.id)}
                          onPinNote={() =>
                            handlePinNote(note?.id, note?.is_pinned)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </InfiniteScroll>
      </div>
      {showDeletePopup && (
        <DeleteNotePopup onClose={onClose} confirmDeletion={confirmDeletion} />
      )}
    </>
  );
}
