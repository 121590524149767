import { useState, useEffect, useRef, useCallback } from "react";
import { useSidebarVisibility } from "../../context/SidebarVisibilityContext";
import { MdVerified } from "react-icons/md";
import TicketsNav from "./TicketsNav";
import { useLocation, useNavigate } from "react-router-dom";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import {
  CALL_NUMBER_CHECK,
  getScheduledTime,
  PRO_USER,
} from "../../utils/constants";
import RescheduleCallModal from "../calls/RescheduleCallModal";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import { sipCallParams } from "../../utils/DeviceDetails/SipDetails";
import EditableText from "../EditableText";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import Loader from "../atom/loader/Loader";
import { checkExtensionStatus } from "../atom/checkExtension";
import dayjs from "dayjs";
import { getAccountType } from "../../routes/ProtectedRoutes";
import { InfoIcon } from "../icons/InfoIcon";
import SaveCallFlow from "./callFlow/SaveCallFlow";
import CallFlowSaved from "./callFlow/CallFlowSaved";
import { CallFlowService } from "../../services/calls/CallFlowService";
import toast from "react-hot-toast";
import CloseTicketPopup from "./CloseTicketPopup";
import pusherNotification from "../pusher/pusherNotification";
import { deviceId } from "../../utils/DeviceDetails/DeviceDetails";

export default function SingleTicketContainer() {
  const scheduledTime = getScheduledTime();
  const { isSidebarVisible } = useSidebarVisibility();
  const dropdownRef = useRef(null);
  const { ticketUpdateCallsDetailsApi, cancelScheduleCallApi } =
    CallDetailsService((state) => state);
  const [showRescheduleCallModal, setShowRescheduleCallModal] = useState(false);
  const [activeTab, setActiveTab] = useState("Status");
  const [stateActiveFilter, setStateActiveFilter] = useState("");
  const [singleTicketData, setSingleTicketData] = useState({});
  const checkExtension = useRef(true);
  const [showCallFlow, setShowFlow] = useState(false);
  const [showOptionsInfo, setShowOptionsInfo] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [ticketDetails, setTicketDetails] = useState("");
  const [searchCallingNumber, setSearchCallingNumber] = useState("");
  const [isTicketClosed, setIsTicketClosed] = useState(false);
  const accountType = getAccountType();
  const navigate = useNavigate();
  const containerClass = `top-[135px] pt-6 pb-2 z-30 flex justify-between w-full bg-white dark:bg-darkModeMain ${
    isSidebarVisible ? "pr-2" : "pr-2 left-0"
  }`;
  const navClass = ` w-full  ${isSidebarVisible ? " " : " -ml-15"}`;
  const handleScheduleCallClick = () => {
    setShowRescheduleCallModal(true);
  };
  const [isCloseTicketPopupVisible, setIsCloseTicketPopupVisible] =
    useState(false);
  const { createCallFlowApi } = CallFlowService();
  const toggleCloseTicketPopup = () => {
    setIsCloseTicketPopupVisible(!isCloseTicketPopupVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptionsInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Event handler to receive pusher notifications
  const deviceUid = deviceId();
  useEffect(() => {
    const channel = pusherNotification.subscribe(deviceUid);
    const eventHandlers = {
      "call-request-started": () => {
        // Call tickets api when event is received
        getSingleTicketDetailsApi();
      },
    };

    Object.keys(eventHandlers).forEach((event) => {
      channel.bind(event, eventHandlers[event]);
    });

    return () => channel.unbind(); // Unbind all callbacks for this channel
  }, [deviceUid]);

  const businessPhoneContactDetails =
    singleTicketData?.business?.business_contacts || [];
  const phoneNumberDetails = businessPhoneContactDetails?.filter(
    (ele) => ele?.account_type?.name == "Phone",
  );
  const { state, pathname } = useLocation();

  const filtername = state?.activeFilter;
  useEffect(() => {
    if (filtername) {
      setStateActiveFilter(filtername);
    }
  }, [filtername]);
  const ticketId = state?.ticketId || pathname?.split("/tickets/")?.[1];

  const showTranscriptTab = state?.showTranscriptTab;
  let businessCallingNumber = state?.callingNumber;

  useEffect(() => {
    if (businessCallingNumber) {
      setSearchCallingNumber(businessCallingNumber);
    }
  }, [businessCallingNumber]);

  const callingNumber =
    businessCallingNumber ||
    phoneNumberDetails?.filter(
      (x) => x?.account_type_id == CALL_NUMBER_CHECK,
    )?.[0]?.account_value ||
    searchCallingNumber ||
    null;

  const {
    listSingleTicketDetails,
    listSingleTicketDetailsApi,
    loadingTicketDetails,
  } = TicketsDetails((state) => state);
  let { status, id } = listSingleTicketDetails?.data || {};

  // Fetch ticket details based on ticketId
  const getSingleTicketDetailsApi = useCallback(async () => {
    if (ticketId) {
      await listSingleTicketDetailsApi(ticketId)?.then((data) => {
        if (data?.data && Object?.keys(data?.data || {})?.length !== 0) {
          setSingleTicketData(data?.data || {});
          setTicketDetails(data?.data.name);
        }
      });
    }
  }, [ticketId, listSingleTicketDetailsApi]);

  // Initial API call to fetch ticket details
  useEffect(() => {
    getSingleTicketDetailsApi();
  }, []);

  const handleNewTicketCall = async (event) => {
    checkExtension.current = false;
    const phoneNumber = singleTicketData.call_history?.[0]?.phone_number;
    if (!phoneNumber) return;

    const sipCallData = sipCallParams(phoneNumber, { ticket_id: ticketId });
    window.postMessage(sipCallData, "*");
  };

  const changeTicketsNavTab = (newTab) => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    if (showTranscriptTab == true && activeTab) {
      setActiveTab("Status");
    }
  }, [showTranscriptTab]);

  const handleCancelCall = async () => {
    await cancelScheduleCallApi({
      ticketId: singleTicketData?.call_requests[0]?.id,
    });
    await getSingleTicketDetailsApi();
  };

  const handleSaveBtn = (data) => {
    ticketUpdateCallsDetailsApi({
      id: singleTicketData?.id,
      data: { name: ticketDetails || "" },
    }).then(() => {
      getSingleTicketDetailsApi();
    });
  };

  const handleCallFlow = (name) => {
    createCallFlowApi({
      call_history_id: singleTicketData?.call_history?.[0]?.id,
      name: name,
    }).then((data) => {
      if (data?.success == true) {
        setShowFlow(false);
        setShowSavedMessage(true);
      } else {
        toast.error(data);
      }
    });
  };

  const updateStatusToClosed = async ({ ticketId, ticketStatus }) => {
    await ticketUpdateCallsDetailsApi({
      id: ticketId,
      data: { status: ticketStatus == "closed" ? "open" : "closed" },
    }).then((response) => {
      if (response?.message == "SUCCESS UPDATE") {
        toast.success(
          `Ticket ${
            ticketStatus == "closed" ? "re-open" : "closed"
          } successfully`,
        );
        setIsCloseTicketPopupVisible(false);
      }
    });
    await getSingleTicketDetailsApi();
  };

  return (
    <div>
      {singleTicketData?.call_requests?.[0]?.call_at_ts && (
        <div className="font-semibold text-[14px] call-cancel-gradient text-white h-[60px] flex items-center pl-4">
          Scheduled call{" "}
          {dayjs().isSame(
            dayjs(singleTicketData?.call_requests?.[0]?.call_at_ts)?.local(),
            `day`,
          )
            ? `Today at ${dayjs(
                singleTicketData?.call_requests?.[0]?.call_at_ts,
              )
                ?.local()
                ?.format("hh:mm A")}`
            : dayjs()
                  .add(1, "day")
                  .isSame(
                    dayjs(
                      singleTicketData?.call_requests?.[0]?.call_at_ts,
                    )?.local(),
                    "day",
                  )
              ? `Tomorrow at ${dayjs(
                  singleTicketData?.call_requests?.[0]?.call_at_ts,
                )
                  ?.local()
                  ?.format("hh:mm A")}`
              : `at ${dayjs(singleTicketData?.call_requests?.[0]?.call_at_ts)
                  ?.local()
                  ?.format("MMMM D, YYYY [at] h:mm A")}`}
          <button
            className="ml-3 w-[125px] border-[1px] rounded-3xl py-1 border-[#fff]"
            onClick={() => handleCancelCall()}
          >
            Cancel
          </button>
        </div>
      )}
      {loadingTicketDetails ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col items-center w-full ">
          <div className={containerClass}>
            <div className="flex flex-col !w-[calc(100%-400px)]">
              <EditableText
                initialText={ticketDetails}
                onChangeText={setTicketDetails}
                triggerSaveChanges={handleSaveBtn}
              />
              <div className="flex ml-3">
                <div
                  onClick={() => {
                    navigate(`/business/${singleTicketData?.business_id}`, {
                      state: {
                        businessId: singleTicketData?.business_id,
                      },
                    });
                  }}
                >
                  <DirectoryImageInitials
                    imageUrl={singleTicketData?.business?.upload?.file_path}
                    alt={singleTicketData?.business?.name}
                    name={singleTicketData?.business?.name}
                    boxColor={singleTicketData?.business?.business_color?.toLowerCase()}
                    className={`m-1 border-none rounded-md max-w-[35px] max-h-[35px]`}
                  />
                </div>
                <div
                  onClick={() => {
                    navigate(`/business/${singleTicketData?.business_id}`, {
                      state: {
                        businessId: singleTicketData?.business_id,
                      },
                    });
                  }}
                  className="flex flex-col hover:cursor-pointer "
                >
                  <div className="flex items-center gap-2 mt-3 pl-2">
                    <p className="text-sm font-semibold capitalize">
                      {singleTicketData?.business?.name}
                    </p>

                    {singleTicketData?.is_verified && (
                      <MdVerified color="#48CFFF" size={16} />
                    )}
                  </div>
                  <p className="pt-0.5 text-sm">
                    {" "}
                    {/* {singleTicketData?.business?.name} */}
                    {singleTicketData?.category?.name || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-3 pr-3">
              {accountType == PRO_USER && (
                <>
                  <button
                    onClick={() => {
                      setShowFlow(true);
                    }}
                    className="uppercase text-[13px] w-[140px] h-[40px] font-semibold dark:hover:bg-white dark:hover:text-black  text-black dark:text-white rounded-full border-[1px] border-black dark:border-darkModeBorder py-[5.5px] px-4 hover:bg-neutral-200  transition-colors"
                  >
                    SAVE FLOW
                  </button>{" "}
                  <div
                    className="h-[40px] items-center flex cursor-pointer"
                    onClick={() => setShowOptionsInfo(!showOptionsInfo)}
                  >
                    <InfoIcon
                      className={
                        showOptionsInfo
                          ? "text-[#4C5FEF] cursor-pointer"
                          : " cursor-pointer text-black dark:text-white"
                      }
                    />
                  </div>
                  {showCallFlow && (
                    <SaveCallFlow
                      handleClose={() => setShowFlow(false)}
                      handleSave={handleCallFlow}
                    />
                  )}
                  {showSavedMessage && (
                    <CallFlowSaved
                      handleClose={() => {
                        setShowSavedMessage(false);
                      }}
                    />
                  )}
                  <div className="absolute !z-50">
                    {showOptionsInfo && (
                      <div
                        className="text-left p-3 z-10 text-[13px] mt-12 w-[320px] h-[129px] divide-y-[1px] dark:divide-darkModeBorder flex flex-col  rounded-lg bg-white dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder"
                        ref={dropdownRef}
                      >
                        <div className="font-semibold py-2 ">Call Flow</div>
                        <div className="py-2">
                          Save the flow of this call so you can get to the
                          representative faster next time you call with a
                          similar issue.
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}{" "}
              {status == "closed" ? (
                <button
                  onClick={() => {
                    let handleApiCall = async () => {
                      await updateStatusToClosed({
                        ticketId: id,
                        ticketStatus: "closed",
                      });
                    };
                    handleApiCall();
                  }}
                  className=" uppercase text-[13px] w-[170px] h-[40px] font-semibold dark:hover:bg-white dark:hover:text-black  text-black dark:text-white rounded-full border-[1px] border-black dark:border-darkModeBorder py-[5.5px] px-4 hover:bg-neutral-200  transition-colors"
                >
                  Re-Open ticket
                </button>
              ) : (
                <button
                  onClick={toggleCloseTicketPopup}
                  className="uppercase text-[13px] w-[170px] h-[40px] font-semibold dark:hover:bg-white dark:hover:text-black  text-black dark:text-white rounded-full border-[1px] border-black dark:border-darkModeBorder py-[5.5px] px-4 hover:bg-neutral-200  transition-colors"
                >
                  Close ticket
                </button>
              )}
              <button
                onClick={handleScheduleCallClick}
                className="bg-black w-[170px] h-[40px] hover:bg-[#383348] dark:hover:bg-white dark:hover:text-black  text-white  text-[13px] uppercase rounded-full font-semibold"
              >
                {singleTicketData?.length == 0 ||
                singleTicketData?.call_requests?.length == 0
                  ? "Schedule call"
                  : singleTicketData?.call_requests?.length !== 0
                    ? "Reschedule call"
                    : "Schedule call"}
              </button>
              <button
                disabled={!callingNumber}
                onClick={() => {
                  handleNewTicketCall();
                  checkExtensionStatus();
                }}
                className=" bg-ipurple w-[170px] h-[40px] hover:bg-[#5A70F9] text-white text-[13px] uppercase rounded-full font-semibold"
              >
                Call Business
              </button>
            </div>
          </div>
          <div className={navClass}>
            <TicketsNav
              callScheduleCheck={
                singleTicketData?.call_requests?.[0]?.id || false
              }
              singleTicketData={singleTicketData}
              businessId={singleTicketData.business_id}
              activeTab={activeTab}
              changeTab={changeTicketsNavTab}
              stateActiveFilter={stateActiveFilter}
              getDataApi={getSingleTicketDetailsApi}
              setActiveTab={(data) => {
                setActiveTab(data);
              }}
            />
          </div>

          <div className="fixed z-30 w-full  top-[125px]">
            {showRescheduleCallModal && (
              <RescheduleCallModal
                onClose={() => setShowRescheduleCallModal(false)}
                ticketId={singleTicketData?.id}
                businessId={singleTicketData?.business_id}
                scheduledTime={
                  singleTicketData?.call_requests?.[0]?.call_at_ts ||
                  scheduledTime
                }
                closeModal={() => {
                  setShowRescheduleCallModal(false);
                }}
                setSingleTicketData={setSingleTicketData}
                getScheduleCallData={(data) => {
                  getSingleTicketDetailsApi();
                  setSingleTicketData({
                    ...singleTicketData,
                    call_requests: singleTicketData?.call_requests?.map(
                      (response) => ({
                        ...response,
                        call_at_ts: data?.call_at_ts,
                      }),
                    ),
                  });
                }}
                singleTicketData={singleTicketData}
                setSelectedScheduledTime={() => {}}
              />
            )}
            {isCloseTicketPopupVisible && (
              <CloseTicketPopup
                onClose={toggleCloseTicketPopup}
                isTicketClosed={isTicketClosed}
                handleApiCall={() => {
                  let handleApiCall = async () => {
                    await updateStatusToClosed({
                      ticketId: id,
                      ticketStatus: status,
                    });
                  };
                  handleApiCall();
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
