import { useState, useEffect, useCallback } from "react";
import { GoItalic, GoPin } from "react-icons/go";
import { HiHashtag } from "react-icons/hi2";
import { IoIosClose, IoIosLink, IoIosSearch } from "react-icons/io";
import { GoPaperclip } from "react-icons/go";
import { MdFormatBold } from "react-icons/md";
import { PiTextUnderlineLight } from "react-icons/pi";
import FilesDropdown from "../tickets-tab/FilesDropdown";
import { DateTimeFormat } from "../../utils/DateTimeFormat";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import Loader from "../atom/loader/Loader";
import { truncateString } from "../../utils/numberFormat";
import { SearchIcon } from "../icons/SearchIcon";
import PropTypes from "prop-types";
export default function NotesContainer(props) {
  let {
    selectedNote,
    isEditorOpen,
    saveNote,
    selectedFiles,
    setSelectedFiles,
    setDeleteNoteDoc,
    businessId,
    noteContent,
  } = props || {};
  const {
    notesPinnedApi,
    modifyNotesData,
    loadingTicketDetails,
    loadinglistNotesDetailsApi,
    listNotesDetails,
    loadingImageUpload,
  } = TicketsDetails((state) => state);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [triggerChange, setTriggerChange] = useState(false);
  console.log(noteContent?.is_pinned, "selectedNote");
  useEffect(() => {
    if (listNotesDetails?.data) {
      props?.setNoteContent(listNotesDetails?.data || {});
    }
  }, [selectedNote, isEditorOpen, listNotesDetails]);

  const togglePinNote = useCallback(async (noteId, pinStatus) => {
    await notesPinnedApi({
      businessId: businessId,
      noteId: noteId,
      pinnedStatus: {
        is_pinned: pinStatus,
      },
    }).then((data) => {
      props?.setNoteContent(data?.data || {});
    });
  }, []);

  const handleBlur = () => {
    setTimeout(() => {
      if (noteContent?.description) {
        saveNote({
          ...noteContent,
          title: truncateString(noteContent?.description || "New Note", 30),
        });
        setTriggerChange(false);
      }
    }, 300);
  };

  const handleSearchClick = () => {
    setIsInputOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const getCallDataApi = setTimeout(() => {
      if (noteContent?.description) {
        if (selectedNote?.id) {
          modifyNotesData({
            id: selectedNote?.id,
            title: truncateString(noteContent?.description || "New Note", 30),
            description: noteContent?.description,
          });
        } else if (triggerChange && noteContent?.description?.length != 0) {
          modifyNotesData({
            id: selectedNote?.id || "new note",
            title: truncateString(noteContent?.description || "New Note", 30),
            description: noteContent?.description,
          });
        }
      }
    }, 300);
    return () => clearTimeout(getCallDataApi);
  }, [noteContent?.description]);

  return (
    <div className="flex flex-col justify-center w-full ">
      <div
        className="flex  items-center h-[60px] w-full justify-end 
      border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder 
      "
      >
        <button className="w-[40px] h-[30px] rounded-lg mx-1 dark:border-darkBorder bg-gray-100  dark:bg-darkModeBG flex justify-center items-center">
          <HiHashtag size={15} />
        </button>

        <button
          className="w-[40px] h-[30px] rounded-lg mx-1 bg-gray-100  dark:border-darkBorder dark:bg-darkModeBG  flex justify-center items-center relative"
          disabled={
            loadingTicketDetails || loadinglistNotesDetailsApi ? true : false
          }
        >
          <input
            type="file"
            accept="image/jpg ,image/jpeg,image/png,pdf,application/pdf,application/vnd.ms-excel"
            name="myImage"
            className="z-50 opacity-0"
            disabled={
              loadingTicketDetails || loadinglistNotesDetailsApi ? true : false
            }
            onChange={(e) => {
              const allowedExtensions = ["jpg", "jpeg", "png", "pdf", "xlsx"]; // Allowed extensions
              const chosenFiles = Array.from(e.target.files).filter((file) => {
                const extension = file.name.split(".").pop().toLowerCase();
                return allowedExtensions.includes(extension);
              });
              e.target.value = null; // Clear the input field value
              setSelectedFiles([...selectedFiles, ...chosenFiles]);
            }}
            multiple
            onBlur={handleBlur}
          />

          <GoPaperclip size={16} className="absolute" />
        </button>
        <button className="w-[40px] h-[30px] mx-1 rounded-lg dark:border-darkBorder bg-gray-100  dark:bg-darkModeBG flex justify-center items-center">
          <IoIosLink size={16} />
        </button>
        <button className="w-[40px] h-[30px] mx-1 rounded-lg dark:border-darkBorder bg-gray-100  dark:bg-darkModeBG flex justify-center items-center">
          <MdFormatBold size={17} />
        </button>
        <button className="w-[40px] h-[30px] mx-1 rounded-lg dark:border-darkBorder bg-gray-100  dark:bg-darkModeBG flex justify-center items-center">
          <GoItalic size={16} />
        </button>
        <button className="w-[40px] h-[30px] mx-1 rounded-lg dark:border-darkBorder bg-gray-100  dark:bg-darkModeBG flex justify-center items-center">
          <PiTextUnderlineLight size={17} />
        </button>
        <button
          disabled={
            loadingTicketDetails || loadinglistNotesDetailsApi ? true : false
          }
          onClick={() =>
            selectedNote &&
            togglePinNote(selectedNote?.id, !noteContent?.is_pinned)
          }
          className={`w-[40px] h-[30px] mx-1 rounded-lg dark:border-darkBorder

            ${noteContent?.is_pinned ? "bg-ipurple text-white" : "bg-gray-100"}

             dark:bg-darkModeBG flex justify-center items-center`}
        >
          <GoPin size={16} />
        </button>

        <div className="inline-block m-3  h-[27px] min-h-[0.5em] w-0.5 self-stretch bg-gray-100 dark:bg-darkModeBorder"></div>
        <div className="mx-2">
          {!isInputOpen && (
            <button onClick={handleSearchClick} className="pr-4">
              <IoIosSearch size={20} />
            </button>
          )}
          {isInputOpen && (
            <div className="relative flex items-center w-[400px] z-30">
              <SearchIcon className="w-[25px] absolute left-2" />
              <input
                type="text"
                id="ticket-search"
                autoComplete="off"
                placeholder="Search business"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full  h-[45px] border-2 border-gray-100 dark:border-darkModeBorder bg-inherit rounded-lg px-10 placeholder:text-sm focus-within:ring focus-within:ring-ipurple"
              />

              <IoIosClose
                onClick={handleSearchClick}
                size={25}
                className="absolute right-2"
              />
            </div>
          )}
        </div>
      </div>

      <div className=" flex flex-col justify-center w-full p-5 !pt-0 overflow-y-auto">
        {isEditorOpen || selectedNote?.id ? (
          <>
            {selectedNote && !isEditorOpen && (
              <p className="text-xs text-center text-gray-400 my-4">
                {DateTimeFormat(selectedNote?.created_at)}
              </p>
            )}
            {loadingTicketDetails ? (
              <Loader />
            ) : (
              <textarea
                autoFocus
                value={noteContent?.description || ""}
                onChange={(e) => {
                  props?.setNoteContent({
                    ...noteContent,
                    ["description"]: e?.target?.value,
                  });

                  if (!selectedNote?.id) {
                    setTriggerChange(true);
                  }
                }}
                onBlur={handleBlur}
                rows="10"
                placeholder="Start typing your note..."
                className="block w-full bg-inherit rounded-md border-1 py-1.5 focus:ring-transparent p-2 pt-5 placeholder:text-gray-400 text-sm sm:leading-6"
              />
            )}

            <div className="w-6/12">
              {Array?.isArray(noteContent?.uploads) &&
                noteContent?.uploads?.map((imgDetails, index) => (
                  <div
                    className="w-full rounded-lg  flex  self-start "
                    key={imgDetails?.id}
                  >
                    <FilesDropdown
                      imgDetailId={imgDetails?.id}
                      imgDetails={index}
                      key={imgDetails?.id}
                      noteContent={noteContent?.uploads}
                      setDeleteNoteDoc={setDeleteNoteDoc}
                      selectedNoteId={selectedNote?.id}
                    />
                  </div>
                ))}
              {loadingImageUpload && <Loader />}
            </div>
          </>
        ) : (
          <p className="text-center pt-4">
            Select a note to view or edit it, or create a new one.
          </p>
        )}
      </div>
    </div>
  );
}

// Props validation
NotesContainer.propTypes = {
  setNoteContent: PropTypes.func.isRequired,
};
