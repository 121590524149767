import PropTypes from "prop-types";
BusinessInfo.propTypes = {
  formik: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
export default function BusinessInfo(props) {
  const { formik, handleChange } = props;
  return (
    <div className="flex flex-col w-full  py-2 space-y-2.5 text-sm">
      <label>Business Name *</label>
      <input
        type="text"
        name="name"
        autoComplete="off"
        value={formik.values.name}
        onChange={handleChange}
        placeholder="Business name"
        className="w-full h-[55px] border-2 placeholder:text-gray-500 border-gray-100 dark:border-darkModeBorder rounded-lg bg-inherit text-sm px-2 active:border-ipurple active:ring-ipurple active:outline-transparent focus:outline-transparent focus:ring-transparent focus:border-ipurple"
      />
      {formik?.errors?.name && (
        <p className="text-danger">{formik?.errors?.name}</p>
      )}
    </div>
  );
}
