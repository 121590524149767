import { useEffect, useRef, useState } from "react";
import { useSearch } from "../../context/SearchContext";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import { IoIosClose } from "react-icons/io";
import { LocationPin, SearchIcon } from "../icons/SearchIcon";
import SearchDropdown from "./SearchDropdown";
import Autocomplete from "react-google-autocomplete";
import {
  DEFAULT_LOCATION_DISTANCE,
  getCurrentLocation,
  MAP_KEYS,
} from "../../utils/constants";
import { sendMixpanelEvent } from "../../services/mixpanelService";

export default function SearchInput() {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const selectedLocationLat = useRef("");
  const selectedLocationLong = useRef("");
  const inputCloseRef = useRef(false);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);
  const [show, setShow] = useState(false);
  const searchValueRef = useRef("");

  const {
    searchTerm,
    setSearchTerm,
    inputRef,
    inputValueRef,
    setSearchViewToggle,
    showDropdown,
    hideDropdown,
    searchViewToggle,
    selectedLocation,
    setSelectedLocation,
    clearSearch,
    locationDetailsRef,
  } = useSearch();

  // Listen to changes in selectedLocation from context
  useEffect(() => {
    if (selectedLocation) {
      setFormattedAddress(selectedLocation);
    }
  }, [selectedLocation]);

  useEffect(() => {
   setFormattedAddress("")
  }, [locationDetailsRef.current]);

  useEffect(() => {
    if (searchTerm) {
      setSearch(searchTerm);
      searchValueRef.current = searchTerm;
    }
  }, [searchTerm]);

  const {
    resetSearch,
    historySearchApi,
    historySearchListApi,
    topNavDirectorySearchApi,
    topNavDirectorySuggestionApi,
    suggestionSearchDetailsData,
    getSearchFilterCategoryApi,
    getLocationDetails,
  } = TopNavSearchService((state) => state);

  const dropdownRef = useRef(null);
  const excludedComponents = [dropdownRef];

  const handleSearch = async (event, isPlaceSelected = false) => {
    
    const currentSearchValue = searchValueRef.current || search;

    if (!isPlaceSelected && event.key === "Tab") {
      setIsDropdownVisible(false);
      return;
    }

    if (
      isPlaceSelected ||
      event.key === "Enter" ||
      event.type === "click"
    ) {
      if (!isPlaceSelected && event.preventDefault) {
        event.preventDefault();
      }
      
      if (selectedLocationLat.current == "") {
        locationDetailsRef.current = "";
      }

      if (!currentSearchValue) {
       
        return;
      }
      
      historySearchApi({ title: currentSearchValue, search_type: "local" });
      hideDropdown();
      setSearchViewToggle(true);
      getLocationDetails({
        lat: selectedLocationLat.current
          ? selectedLocationLat.current
          : latitude,
        lng: selectedLocationLong.current
          ? selectedLocationLong.current
          : longitude,
      });

      topNavDirectorySearchApi({
        LocationDistance: DEFAULT_LOCATION_DISTANCE,
        keyword: currentSearchValue,
        locationLat: selectedLocationLat.current
          ? selectedLocationLat.current
          : latitude,
        locationLong: selectedLocationLong.current
          ? selectedLocationLong.current
          : longitude,
        location: formattedAddress,
        user_lat: latitude,
        user_lng: longitude,
      });
      getSearchFilterCategoryApi(currentSearchValue);
    } else {
      setIsDropdownVisible(true);
    }
  };

  const handleChange = (event) => {
    const value = event.target?.value;
    setSearch(value);
    searchValueRef.current = value;
    inputValueRef.current = value;
    setShow(false);
  };

  useEffect(() => {
    if (search?.length >= 3) {
      const timer = setTimeout(() => {
        topNavDirectorySuggestionApi({
          keyword: search,
          locationLat: selectedLocationLat.current
            ? selectedLocationLat.current
            : latitude,
          locationLong: selectedLocationLong.current
            ? selectedLocationLong.current
            : longitude,
          currentPage: currentPage,
        });
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [search, currentPage]);

  useEffect(() => {
    getCurrentLocation(
      (lat, lon) => {
        setLatitude(lat);
        setLongitude(lon);
      },
      (error) => {
        // console.error("Error getting location", error);
      },
    );
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !excludedComponents.some((component) =>
          component.current.contains(event.target),
        )
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, excludedComponents]);

  const handleSearchHistory = async () => {
    sendMixpanelEvent("Business Search Initiated");
    const fetchTitles = async () => {
      try {
        const response = await historySearchListApi();
        const titlesArray = response.data.map((item) => item.title);
        setSearchHistory(titlesArray);
      } catch (error) {
        console.error("Error fetching search history:", error);
      }
    };
    setShow((prev) => !prev);
    fetchTitles();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputCloseRef.current &&
        !inputCloseRef.current.contains(event.target) &&
        !excludedComponents.some((component) =>
          component.current.contains(event.target),
        )
      ) {
        setShow(false);
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputCloseRef, excludedComponents]);

  return (
    <>
      <style>
        {`
        .pac-container {
          width: 20% !important;
          background-color: #fff !important;
          border-radius: 0 0 10px 10px !important;
          box-shadow: 0 4px 6px rgba(0,0,0,0.1) !important;
          padding: 10px !important;
          margin-top: 20px !important;
          margin-right: 120px !important;
          border: none !important;
          divide: none !important;
        }
        html.dark .pac-container {
          background-color: #1B1D21 !important;
          color: #fff !important;
        }
        .auto-complete-container {
          position: relative !important;
        }
        html.dark .pac-item .pac-icon {
          filter: brightness(0) invert(1); 
        }
        .pac-item {
          border: none !important;
          width: 100% !important;
        }
        html.dark .pac-item {
          color: #fff !important
        }
        html.dark .pac-item span {
          color: #fff !important; 
        }
        .pac-item:hover {
          background-color: #f9fcff !important;
        }
        html.dark .pac-item:hover {
          background-color: #232529 !important;
        }
        `}
      </style>

      <div
        id="step-2"
        className="relative w-full max-w-[708px] flex text-sm h-[55px] max-h-[50px] border-gray-100 dark:border-darkModeBorder border-[1px] bg-white dark:bg-darkModeMain rounded-lg items-center below-910:max-w-[500px]"
      >
        <div className="relative flex items-center w-[360px]">
          <div className="w-full absolute -left-2 top-[44px] ">
            <div className="w-[360px] -left-2 z-[999]">
              {isDropdownVisible &&
                suggestionSearchDetailsData.length !== 0 && (
                  <div ref={inputCloseRef}>
                    <SearchDropdown
                      titles={searchHistory}
                      suggestionsData={suggestionSearchDetailsData}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      dropdownRef={dropdownRef}
                      setIsDropdownVisible={(data) => {
                        setIsDropdownVisible(data);
                        setShow(false);
                      }}
                      searchInDashboard={search}
                    />
                  </div>
                )}
            </div>

            <div className="w-[360px] -left-2 z-[999] dark:text-white">
              {show && searchHistory.length > 0 && (
                <div ref={inputCloseRef}>
                  <SearchDropdown
                    searchHistory={searchHistory}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    dropdownRef={dropdownRef}
                    setSearch={(searchValue) => {
                      setSearch(searchValue);
                      searchValueRef.current = searchValue;
                    }}
                    setIsDropdownVisible={(data) => {
                      setShow(false);
                      setIsDropdownVisible(data);
                    }}
                    searchInDashboard={search}
                  />
                </div>
              )}
            </div>
          </div>

          <input
            value={search}
            onChange={handleChange}
            onKeyDown={handleSearch}
            onClick={(e) => {
              handleSearchHistory(e);
            }}
            className="w-full h-full px-1.5 ml-[10px] font-semibold text-[14px] opacity-100 border-none rounded placeholder:text-gray-400 placeholder:font-normal focus:ring-transparent placeholder:text-[14px] dark:bg-darkModeMain dark:text-white"
            type="text"
            placeholder="Type to search businesses"
            ref={inputRef}
          />

          {search?.length > 0 && (
            <button
              tabIndex="-1"
              ref={dropdownRef}
              onClick={() => {
                sendMixpanelEvent("Search Results Cleared");
                clearSearch();
                resetSearch();
                setSearch("");
                searchValueRef.current = "";
                hideDropdown();
              }}
              className="flex items-center py-1 mr-2 text-sm text-gray-500 border border-transparent rounded hover:text-gray-600 focus:outline-none dark:text-gray-400"
            >
              <p className="max-[1350px]:hidden">Clear</p>
              <IoIosClose size={20} className="ml-1" />
            </button>
          )}
        </div>

        <div className="h-[30px] w-[1px] bg-gray-200 dark:bg-darkModeBorder"></div>

        <div className="relative flex items-center w-[340px] auto-complete-container dark:text-white">
          <div className="flex items-center gap-2.5 dark:text-white">
            <LocationPin />
          </div>
          <Autocomplete
            apiKey={MAP_KEYS}
            id="google-place-details"
            onPlaceSelected={(place) => {
              if (place?.formatted_address) {
                locationDetailsRef.current = place?.formatted_address;
                setSelectedLocation(place?.formatted_address);
              }
              setFormattedAddress(place?.formatted_address);
              selectedLocationLat.current = place?.geometry?.location?.lat() || "";
              selectedLocationLong.current = place?.geometry?.location?.lng() || "";
              handleSearch({}, true);
            }}
            options={{
              types: ["geocode", "establishment"],
            }}
            value={formattedAddress}
            language="en"
            onKeyDown={handleSearch}
            onChange={(data) => {
              if (data?.target?.value === "") {
                selectedLocationLat.current = "";
                selectedLocationLong.current = "";
                locationDetailsRef.current = "";
                setFormattedAddress("");
                setSelectedLocation("");
              }
              setFormattedAddress(data?.target?.value);
            }}
            className="w-full h-full text-[13px] border-none rounded opacity-100 font-semibold placeholder:font-normal placeholder:text-[13px] focus:text-semibold active:text-semibold focus:ring-transparent dark:bg-inherit"
          />
          <button
            onClick={handleSearch}
            className="w-[48px] min-w-[48px] absolute right-0 flex justify-center items-center text-white h-[50px] min-h-[50px] bg-black rounded-r-lg -mr-2"
          >
            <SearchIcon className="svg-icon text-white w-[25px]" />
          </button>
        </div>
      </div>
    </>
  );
}
